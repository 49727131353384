import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'


const withAuthorizationAdmin = (userRole) => Component => {
  class WithAuthorization extends React.Component {

    render() {
      //console.log(userRole)
      const { auth } = this.props
      //console.log(auth.user.email)
      if(!auth.isAuth) {
        return <Redirect to="/login" />
      }
      if (auth.user.role === 'admin') {
        return auth.isAuth ? <Component {...this.props} /> : <Redirect to="/login" />
      } else {
        return <Redirect to="/login" />
      }
      
    }
  }

  return connect(({auth}) => ({auth}))(WithAuthorization)
}


export default withAuthorizationAdmin