

import firebase from 'firebase/app'
import 'firebase/firestore'

const db = firebase
    .initializeApp(
        {
            apiKey: "AIzaSyBFRMZBhbVsDs3EpBVoWP8ILB5CZqKVu2A",
            authDomain: "audiospookcario.firebaseapp.com",
            databaseURL: "https://audiospookcario.firebaseio.com",
            projectId: "audiospookcario",
            storageBucket: "audiospookcario.appspot.com",
            messagingSenderId: "595107639513",
            appId: "1:595107639513:web:a1d00157fdd4411f954ec8",
            measurementId: "G-180VX0MLXZ"
        }
    )
    .firestore()

export default db

const { Timestamp } = firebase.firestore
export { Timestamp }