
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import withAuthorizationAdmin from 'components/hoc/withAuthorizationAdmin'
import { fetchUserDevices } from 'api'
import DeviceItem from 'components/device/DeviceItem'


const UserSelectedDevices = (props) => {

    const { t, auth } = props
    const { userId } = useParams()

    const [ devices, setDevices ] = useState([])
  
    fetchUserDevices(userId)
            .then(result => {
                //console.log(result)
                setDevices(result)
            })
            .catch(() => alert('SOME ERROR!'))
    

    return (

        <div className="create-page">
            <div className="container">
                
                <div className="container">
                    {devices.length != 0 ? 
                        <div className="columns is-multiline">
                            {
                                devices.map(s => (
                                        <div key={s.id} className="column">
                                            <DeviceItem device={s} />
                                        </div>
                                    )
                                )
                            }
                        </div>
                        : <div></div>
                    }
                </div>
            </div>
        </div>


    )
}

export default withNamespaces()(withAuthorizationAdmin("admin")(UserSelectedDevices))