/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

const UserItem = (props) => {

    const { t, user } = props

    const shortText = (text, maxLength = 50) => {
        if (!text) { return ' '};
        if (text.length <= maxLength) { return text};

        return text.substr(0, maxLength) + '...';
    }

    return (


        <div
          className="column is-one-third">
          <div className="feature-card is-bordered has-text-centered revealOnScroll delay-1" data-animation="fadeInLeft">
            <div className="card-title">
              <h4>{user.email}</h4>
            </div>
            <div className="card-icon">
              <img src={user.avatar} alt=""/>
            </div>
            <div className="card-text">
              <p>{shortText(user.fullName)}</p>
            </div>
            <div className="card-action">
              <Link 
                to={"/userdevices/"+`${user.uid}`}
                className="button btn-align-md accent-btn raised">{props.t('UserItem_1')}</Link>
            </div>
          </div>
        </div>
    )
}

export default withNamespaces()(UserItem)
