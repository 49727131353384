import { combineReducers } from 'redux'
import services from './services'
import selectedService from './selectedService'
import auth from './auth'
import user from './user'
import selectedDevice from './selectedDevice'

const serviceApp = combineReducers({
    services,
    selectedService,
    auth,
    user,
    selectedDevice
})

export default serviceApp;