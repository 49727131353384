/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState } from 'react'
import RegisterForm from 'components/auth/RegisterForm'
import { register } from 'actions'
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router-dom'
import onlyGuest from 'components/hoc/onlyGuest'

//import { withRouter } from 'react-router-dom'

//import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';

const Register = (props) => {

    const { t } = props

    const [ redirect, setRedirect ] = useState(false)
    const { addToast } = useToasts()

    const registerUser = (userData) => {
        //props.history.push('/')
        register(userData)
            .then(
                _ => setRedirect(true), 
                errorMessage => addToast(errorMessage, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 }))
    }

    if (redirect) { return <Redirect to="/" />}

    return (
        <div className="auth-page">
            <div className="container has-text-centered">
                <div className="column is-4 is-offset-4">
                <h3 className="title has-text-grey">{t('Register_1')}</h3>
                <p className="subtitle has-text-grey">{t('Register_2')}</p>
                <div className="box">
                    <figure className="avatar">
                        <img src="logo_audiospook_1.svg" alt="audiospook_logo"/>
                    </figure>
                    <RegisterForm onRegister={registerUser} />
                </div>
                <p className="has-text-grey">
                    <a>{t('Register_3')}</a>&nbsp;
                    <a href="/">{t('Register_4')}</a> &nbsp;·&nbsp;
                    <a href="../">{t('Register_5')}</a>
                </p>
                </div>
            </div>
        </div>
                )

}

//export default withNamespaces()(withRouter(Register))
export default withNamespaces()(onlyGuest(Register))