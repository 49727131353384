import withAuthorizationOrAdmin from 'components/hoc/withAuthorizationOrAdmin'
import { withNamespaces } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import { connect } from 'react-redux'
import { fetchDeviceById } from 'actions'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'

import Spinner from 'components/Spinner'
import Select from 'react-select';

import { setLAeq1mDeviceTrigger, setLAmax1mDeviceTrigger, setLAmin1mDeviceTrigger } from 'api'



const DeviceConfig = props => {

  
    const { deviceId } = useParams()
    const { t, device, fetchDeviceById, isFetching } = props
    const [fetchingData, setFetchingData] = useState(false)

    const [checked_trigger1, setChecked_trigger1] = React.useState(false)
    const [checked_trigger2, setChecked_trigger2] = React.useState(false)
    const [checked_trigger3, setChecked_trigger3] = React.useState(false)

    const [value_trigger1, setValue_trigger1] = React.useState(0)
    const [value_trigger2, setValue_trigger2] = React.useState(0)
    const [value_trigger3, setValue_trigger3] = React.useState(0)

    const { addToast } = useToasts()

    let triggerLevelInput = React.createRef()
    let selectedLA = "LAeq,1m"

    const optionsTrigger = [
        { value: "LAeq1m", label: "LAeq,1m" },
        { value: "LAmax1m", label: "LAmax,1m" },
        { value: "LAmin1m", label: "LAmin,1m" }
      ]


    useEffect(() => {
        fetchDeviceById(deviceId)
        console.log(device.LAeq1m_trigger)
        console.log("PASO POR EL USE EFFECT!!!")
        setValue_trigger1(device.LAeq1m_trigger)

    }, [deviceId, fetchDeviceById])



    const handleChangeCheckBox_trigger1 = (event) => {
        setChecked_trigger1(event.target.checked)
        console.log(checked_trigger1)
    }

    const handleChangeCheckBox_trigger2 = (event) => {
        setChecked_trigger2(event.target.checked)
        console.log(checked_trigger2)
    }

    const handleChangeCheckBox_trigger3 = (event) => {
        setChecked_trigger3(event.target.checked)
        console.log(checked_trigger3)
    }

    /*
    const handleTriggerLevelInput = (event) => {
        console.log(triggerLevelInput.current.value)
        console.log(selectedLA)
        switch(selectedLA) {
            case "LAeq,1m": setValue_trigger1(triggerLevelInput.current.value)
                            break
            case "LAmin,1m":  setValue_trigger3(triggerLevelInput.current.value)
                              break
            case "LAmax,1m":  setValue_trigger2(triggerLevelInput.current.value)
                              break
          }
        //console.log(triggerLevelInput.current.value)
    }
    */


    const handleChangeTriggerOption = (selectedOption) => {
        switch(selectedOption.value) {
          case "LAeq1m": selectedLA = "LAeq,1m"
                          break
          case "LAmin1m":  selectedLA = "LAmin,1m"
                            break
          case "LAmax1m":  selectedLA = "LAmax,1m"
                            break
        }
    }



    const handleSubmit = () => {

        let value = 0
        let device_checked_trigger1 = checked_trigger1
        let device_checked_trigger2 = checked_trigger2
        let device_checked_trigger3 = checked_trigger3
        let device_value

        switch (selectedLA) {

            case "LAeq,1m": if (!triggerLevelInput.current.value) {
                                value = device.LAeq1m_trigger
                                device_value = device.LAeq1m_trigger
                            } else {
                                value = triggerLevelInput.current.value
                                device_value = triggerLevelInput.current.value
                            }
                            setLAeq1mDeviceTrigger(deviceId, value, checked_trigger1, checked_trigger2, checked_trigger3)
                            .then(
                                _ => (window.location.reload(false),
                                    axios.request({
                                        method: 'POST',
                                        url: '/sendtriggers',
                                        data: {
                                            device: device,
                                            value: device_value,
                                            data: "LAeq1m",
                                            checked_trigger1: device_checked_trigger1,
                                            checked_trigger2: device_checked_trigger2,
                                            checked_trigger3: device_checked_trigger3
                                        }
                                    })), 
                                errorMessage => addToast(errorMessage, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 }))
                            break

            case "LAmax,1m": if (!triggerLevelInput.current.value) {
                                value = device.LAmax1m_trigger
                                device_value = device.LAmax1m_trigger
                             } else {
                                value = triggerLevelInput.current.value
                                device_value = triggerLevelInput.current.value
                             }
                             setLAmax1mDeviceTrigger(deviceId, value, checked_trigger1, checked_trigger2, checked_trigger3)
                             .then(
                                _ => (window.location.reload(false),
                                    axios.request({
                                        method: 'POST',
                                        url: '/sendtriggers',
                                        data: {
                                            device: device,
                                            value: device_value,
                                            data: "LAmax1m",
                                            checked_trigger1: device_checked_trigger1,
                                            checked_trigger2: device_checked_trigger2,
                                            checked_trigger3: device_checked_trigger3
                                        }
                                    })), 
                                errorMessage => addToast(errorMessage, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 }))
                            break
                            
            case "LAmin,1m": if (!triggerLevelInput.current.value) {
                                value = device.LAmin1m_trigger
                                device_value = device.LAmax1m_trigger
                             } else {
                                value = triggerLevelInput.current.value
                                device_value = triggerLevelInput.current.value
                             }
                             setLAmin1mDeviceTrigger(deviceId, value, checked_trigger1, checked_trigger2, checked_trigger3)
                             .then(
                                _ => (window.location.reload(false),
                                    axios.request({
                                        method: 'POST',
                                        url: '/sendtriggers',
                                        data: {
                                            device: device,
                                            value: device_value,
                                            data: "LAmin1m",
                                            checked_trigger1: device_checked_trigger1,
                                            checked_trigger2: device_checked_trigger2,
                                            checked_trigger3: device_checked_trigger3
                                        }
                                    })), 
                                errorMessage => addToast(errorMessage, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 }))
                            break

        }    
        
        
    }


    const handleSubmit2 = () => {
        axios.request({
            method: 'POST',
            url: '/ota',
            data: {
                device: device
            }
        })
        addToast('Device upgrading ...', { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 })
    }

    
    if (isFetching || deviceId !== device.id) { return <Spinner /> }
    if (fetchingData) { return <Spinner /> }

    
    else  {

        return (
            <section className="hero is-fullheight is-default is-bold">
                <div className="hero-device">
                    <div className="container has-text-centered">
          
                
                        <div className="hero-body">
                            <div className="container">
                                
                                
                                <div className="columns is-vcentered">
            
                                    <div className="column is-4">
                                        <br/>
                                        <h1 className="title is-2">
                                            {device.name}
                                        </h1>
                                        <h2 className="subtitle is-4">
                                            {device.description}
                                        </h2>
                                    </div>
            

                                    <div className="column is-2 is-offset-2">
                                        <div className="hero-graph">

                                            <div>
                                                <h2>LAeq,1m</h2>
                                                <h1 className="title">{device.LAeq1m_trigger}</h1>
                                            </div>
                                            <Checkbox
                                                    checked={device.LAeq1m_trigger_activated}
                                                    disabled="true"
                                            />
                                            <Checkbox
                                                    checked={checked_trigger1}
                                                    onChange={handleChangeCheckBox_trigger1}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                            /> 
                                            <br/>
                                            <br/>
                                            
                                            <div>
                                                <h2>LAmax,1m</h2>
                                                <h1 className="title">{device.LAmax1m_trigger}</h1>
                                            </div>
                                            <Checkbox
                                                    checked={device.LAmax1m_trigger_activated}
                                                    disabled="true"
                                            /> 
                                            <Checkbox
                                                    checked={checked_trigger2}
                                                    onChange={handleChangeCheckBox_trigger2}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                            /> 
                                            <br/>
                                            <br/> 

                                            <div>
                                                <h2>LAmin,1m</h2>
                                                <h1 className="title">{device.LAmin1m_trigger}</h1>   
                                            </div> 
                                            <Checkbox
                                                    checked={device.LAmin1m_trigger_activated}
                                                    disabled="true"
                                            />
                                            <Checkbox
                                                    checked={checked_trigger3}
                                                    onChange={handleChangeCheckBox_trigger3}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>



                                    <div className="column is-2 is-offset-1">
                                        <Select
                                            defaultValue={optionsTrigger[0]}
                                            label="Single select"
                                            options={optionsTrigger}
                                            onChange={handleChangeTriggerOption}
                                        />
                                        <input ref={triggerLevelInput} placeholder={t('DeviceConfig_1')} />
                                    </div>

                                    <button
                                        onClick={handleSubmit}
                                        type="button" 
                                        className="button is-link">{t('DeviceConfig_2')}</button>
                
                                </div>
            
                            </div>
                        
                        </div>
                        <button
                                        onClick={handleSubmit2}
                                        type="button" 
                                        className="button is-link">{t('DeviceConfig_3')}</button>
                    </div>
                    
                </div>
            </section>
          
          )

    }

}


const mapStateToProps = ({selectedDevice}) => (
    {
        device: selectedDevice.item,
        isFetching: selectedDevice.isFetching
    }
)


export default withNamespaces()(withAuthorizationOrAdmin("admin")(connect(mapStateToProps, {fetchDeviceById})(DeviceConfig)))