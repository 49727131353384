/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react'

//import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';

const Hero = (props) => 
        <section className="hero is-default is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-5 is-offset-1 landing-caption">
                  <h1 className="title is-1 is-bold is-spaced">
                      {props.t('Hero_1')}
                  </h1>
                  <h2 className="subtitle is-5 is-muted">{props.t('Hero_2')}</h2>
                  <p>
                    <a className="button cta rounded primary-btn raised">
                    {props.t('Hero_3')}
                    </a>
                  </p>
                </div>
                <div className="column is-5 is-offset-1">
                  <figure className="image is-4by3">
                      <img src={process.env.PUBLIC_URL + '/logo_audiospook_1.svg'} alt="Description" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

export default withNamespaces()(Hero)