import { FETCH_DEVICE_SUCCESS, REQUEST_DEVICE } from 'types'
import { combineReducers } from 'redux'



const initSelectedDevice = () => {

    const item = (state = {}, action) => {

        switch(action.type) {
            case FETCH_DEVICE_SUCCESS:
                return action.device
            default:
                return state
        }
    }

    const isFetching = (state = false, action) => {
        switch(action.type) {
            case REQUEST_DEVICE:
                return true
            case FETCH_DEVICE_SUCCESS:
                return false
            default:
                return state
            
        }
    }

    return combineReducers({
        item,
        isFetching
    })
}

const selectedDevice = initSelectedDevice()
export default selectedDevice