/* eslint no-useless-escape: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState } from 'react';
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router-dom'
import onlyGuest from 'components/hoc/onlyGuest'

import { login } from 'actions'

// import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';

const Login = ({t}) => {

    const [ redirect, setRedirect ] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const { addToast } = useToasts()

    const onLogin = loginData => {
        login(loginData)
            .then(
                _ => setRedirect(true),
                errorMessage => addToast(errorMessage, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 })
            )
    }

    if (redirect) { return <Redirect to="/" />}

    return (

        <div className="auth-page">
            <div className="container has-text-centered">
                <div className="column is-4 is-offset-4">
                <h3 className="title has-text-grey">{t('Login_1')}</h3>
                <p className="subtitle has-text-grey">{t('Login_2')}</p>
                <div className="box">
                    <figure className="avatar">
                        <img src="logo_audiospook_1.svg" alt="audiospook_logo"/>
                    </figure>
                    <form onSubmit={handleSubmit(onLogin)}>
                        <div className="field">
                            <div className="control">
                                <input  ref={register({required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                        name="email"
                                        className="input is-large"
                                        type="email"
                                        placeholder={t('Login_3')}
                                        autoComplete="email" />
                                { errors.email &&
                                    <div className="form-error">
                                        { errors.email.type === 'required' && <span className="help is-danger">{t('Login_4')}</span>}
                                        { errors.email.type === 'pattern' && <span className="help is-danger">{t('Login_5')}</span>}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <input  ref={register({required: true, minLength: 6})}
                                        name="password"
                                        className="input is-large"
                                        type="password"
                                        placeholder={t('Login_6')}
                                        autoComplete="current-password" />
                                { errors.password &&
                                    <div className="form-error">
                                        { errors.password.type === 'required' && <span className="help is-danger">{t('Login_7')}</span> }
                                        { errors.password.type === 'minLength' && <span className="help is-danger">{t('Login_12')}</span> }
                                    </div>
                                }
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="button is-block is-info is-large is-fullwidth">{t('Login_8')}</button>
                    </form>
                </div>
                <p className="has-text-grey">
                    <a>{t('Login_9')}</a>&nbsp;
                    <a href="/">{t('Login_10')}</a> &nbsp;·&nbsp;
                    <a href="../">{t('Login_11')}</a>
                </p>
                </div>
            </div>
        </div>

    )

}

export default withNamespaces()(onlyGuest(Login))