/* eslint no-useless-escape: 0 */

import React from 'react'
import { useForm } from 'react-hook-form'

import { isValidImage, isValidUrl, sameAs } from 'helpers/validators'

//import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

const RegisterForm = (props) => {

    const { t } = props

    const { register, handleSubmit, errors, getValues } = useForm()

    return (
        <form onSubmit={handleSubmit(props.onRegister)}>
            <div className="field">
                <div className="control">
                    <input  ref={register({required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                            name="email"
                            className="input is-large"
                            type="email"
                            placeholder={t('RegisterForm_1')}
                            autoComplete="email" />
                    { errors.email &&
                        <div className="form-error">
                            { errors.email.type === 'required' && <span className="help is-danger">{t('RegisterForm_2')}</span>}
                            { errors.email.type === 'pattern' && <span className="help is-danger">{t('RegisterForm_3')}</span>}
                        </div>
                    }
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input  ref={register({required: true, minLength: 8})}
                            name="fullName"
                            className="input is-large"
                            type="text"
                            placeholder={t('RegisterForm_4')}
                            />
                    { errors.fullName &&
                        <div className="form-error">
                            { errors.fullName.type === 'required' && <span className="help is-danger">{t('RegisterForm_5')}</span>}
                            { errors.fullName.type === 'minLength' && <span className="help is-danger">{t('RegisterForm_6')}</span>}
                        </div>
                    }
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input  ref={register({required: true, validate: {isValidImage, isValidUrl} })}
                            name="avatar"
                            className="input is-large"
                            type="text"
                            placeholder={t('RegisterForm_7')}
                            />
                    { errors.avatar &&
                        <div className="form-error">
                            { errors.avatar.type === 'required' && <span className="help is-danger">{t('RegisterForm_8')}</span>}
                            { errors.avatar.type === 'isValidImage' && <span className="help is-danger">{t('RegisterForm_9')}</span>}
                            { errors.avatar.type === 'isValidUrl' && <span className="help is-danger">{t('RegisterForm_16')}</span>}
                        </div>
                    }
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input  ref={register({required: true, minLength: 6})}
                            name="password"
                            className="input is-large"
                            type="password"
                            placeholder={t('RegisterForm_10')}
                            autoComplete="current-password" />
                    { errors.password &&
                        <div className="form-error">
                            { errors.password.type === 'required' && <span className="help is-danger">{t('RegisterForm_11')}</span> }
                            { errors.password.type === 'minLength' && <span className="help is-danger">{t('RegisterForm_15')}</span> }
                        </div>
                    }
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input  ref={register({required: true, minLength: 6, validate: {sameAs: sameAs(getValues, 'password')} })}
                            name="passwordConfirmation"
                            className="input is-large"
                            type="password"
                            placeholder={t('RegisterForm_12')}
                            autoComplete="current-password" />
                    { errors.passwordConfirmation &&
                        <div className="form-error">
                            { errors.passwordConfirmation.type === 'required' && <span className="help is-danger">{t('RegisterForm_13')}</span> }
                            { errors.passwordConfirmation.type === 'minLength' && <span className="help is-danger">{t('RegisterForm_15')}</span> }
                            { errors.passwordConfirmation.type === 'sameAs' && <span className="help is-danger">{t('RegisterForm_17')}</span> }
                        </div>
                    }
                </div>
            </div>
            <button
                type="submit"
                className="button is-block is-info is-large is-fullwidth">{t('RegisterForm_14')}
            </button>
        </form>
    )
}

export default withNamespaces()(RegisterForm)