
import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomePage from './pages/Home'
import FaqPage from './pages/Faq'
import ProfilePage from './pages/Profile'
import ServicesPage from './pages/Services'
import ServiceDetailPage from './pages/ServiceDetail'
import LoginPage from './pages/Login'
import RegisterPage from './pages/Register'
import SecretPage from './pages/Secret'
import AdminPage from './pages/Admin'

import AdminUsersPage from './pages/ControlPanel'

import DeviceCreatePage from './pages/devices/DeviceNew'
import UserDevicesPage from './pages/devices/UserDevices'
import UserSelectedDevicesPage from './pages/devices/UserSelectedDevices'
import DeviceDetailPage from './pages/devices/DeviceDetail'
import DeviceConfigPage from './pages/devices/DeviceConfig'
import ControlPanelPage from './pages/ControlPanel'


const Routes = () => 
  <Switch>
    <Route path="/admin">
      <AdminPage />
    </Route>
    <Route path="/secret/:deviceId">
      <SecretPage />
    </Route>
    <Route path="/secret">
      <SecretPage />
    </Route>
    <Route path="/register">
      <RegisterPage />
    </Route>
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/controlpanel">
      <ControlPanelPage />
    </Route>
    <Route path="/userdevices/:userId">
      <UserSelectedDevicesPage />
    </Route>
    <Route path="/deviceconfig/:deviceId">
      <DeviceConfigPage />
    </Route>
    <Route path="/devices/me">
      <UserDevicesPage />
    </Route>
    <Route path="/devices/new">
      <DeviceCreatePage />
    </Route>
    <Route path="/devices/:deviceId">
      <DeviceDetailPage />
    </Route>
    <Route path="/services/:serviceId">
      <ServiceDetailPage />
    </Route>
    <Route path="/services">
      <ServicesPage />
    </Route>
    <Route path="/profile">
      <ProfilePage />
    </Route>
    <Route path="/faq">
      <FaqPage />
    </Route>
    <Route path="/">
      <HomePage />
    </Route>
  </Switch>

export default Routes