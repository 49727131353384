import withAuthorizationOrAdmin from 'components/hoc/withAuthorizationOrAdmin'
import { withNamespaces } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchDeviceById } from 'actions'

import Spinner from 'components/Spinner'
import Calendar from 'react-calendar'
import { ResponsiveLine } from '@nivo/line'
import Checkbox from '@material-ui/core/Checkbox'
import Select from 'react-select'

import { CSVLink, CSVDownload } from 'react-csv'
import ReactSpeedometer from 'react-d3-speedometer'

import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

var keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title"
}

const groups = [{ id: 1, title: 'L24h' }, { id: 2, title: 'Ld' }, { id: 3, title: 'Le' }, { id: 4, title: 'Ln' }]

//const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

const items = []


/*
const items = [
  {
    id: 1,
    group: 1,
    title: 'item 1',
    start_time: moment(),
    end_time: moment().add(1, 'hour')
  },
  {
    id: 2,
    group: 2,
    title: 'item 2',
    start_time: moment().add(-0.5, 'hour'),
    end_time: moment().add(0.5, 'hour')
  },
  {
    id: 3,
    group: 1,
    title: 'item 3',
    start_time: moment().add(2, 'hour'),
    end_time: moment().add(3, 'hour')
  }
]
*/



const defaultTimeStart = moment()
  .startOf("day")
  .toDate()

const defaultTimeEnd = moment()
  .startOf("day")
  .add(1, "day")
  .toDate()



var count = 1

let initParsedData_LAeq1m
let initParsedData_LAeq1m_min
let initParsedData_LAeq1m_max
let initData_LAeq1m
let initData_LAeq1m_min
let initData_LAeq1m_max
let refreshData
let refreshParsedData_LAeq1m
let refreshParsedData_LAeq_complete

let csvHeaders = new Array()
let csvDia = ""
let csvMes = ""
let csvYear = ""

const options = [
  { value: "24h", label: "24h" },
  { value: "0-6h", label: "0-6h" },
  { value: "6-12h", label: "6-12h" },
  { value: "0-12h", label: "0-12h" },
  { value: "12-16h", label: "12-16h" },
  { value: "16-24h", label: "16-24h" },
  { value: "12-24h", label: "12-24h" }
]

const options2 = [
  { value: "1m", label: "1m" },
  { value: "5m", label: "5m" },
  { value: "15m", label: "15m" },
  { value: "30m", label: "30m" },
  { value: "1h", label: "1h" }
]

let selectedLA = "1m"
let selectedWindowTime = "24h"

let arrancamos = true

let parsedData = new Array()
let parsedData_min = new Array()
let parsedData_max = new Array()
let parsedData_90 = new Array()
let parsedData_10 = new Array()
let parsedDataComplete = new Array()
let parsedDataComplete_min = new Array()
let parsedDataComplete_max = new Array()
let parsedDataComplete_90 = new Array()
let parsedDataComplete_10 = new Array()
let parsedData_LAeq1m = new Array()
let parsedData_LAmin1m = new Array()
let parsedData_LAmax1m = new Array()
let parsedData_LA901m = new Array()
let parsedData_LA101m = new Array()
let parsedDataCSV = new Array()

let reparsedData = new Array()
let reparsedData_90 = new Array()
let reparsedData_10 = new Array()
let reparsedDataComplete = new Array()
let reparsedDataComplete_90 = new Array()
let reparsedDataComplete_10 = new Array()
let reparsedData_LAeq1m = new Array()
let reparsedData_LA901m = new Array()
let reparsedData_LA101m = new Array()

let reparsedData_LAeq1m_array = new Array()
let reparsedData_LAmin1m_array = new Array()
let reparsedData_LAmax1m_array = new Array()
let reparsedData_LA901m_array = new Array()
let reparsedData_LA101m_array = new Array()

let reparsedData_RT_array = new Array()

let parseadoData_LAeq1m_array = new Array()
let parsedData_LAeq5m_array = new Array()
let parsedData_LAeq15m_array = new Array()
let parsedData_LAeq30m_array = new Array()
let parsedData_LAmin30m_array = new Array()
let parsedData_LAmax30m_array = new Array()
let parsedData_LA9030m_array = new Array()
let parsedData_LA1030m_array = new Array()
let parsedData_LAeq1h_array = new Array()
let parsedData_LAmin1h_array = new Array()
let parsedData_LAmax1h_array = new Array()
let parsedData_LA901h_array = new Array()
let parsedData_LA101h_array = new Array()


let level_Ld = new Array()
let level_Le = new Array()
let level_Ln = new Array()


let checked_LAeq1m = true
let checked_LAmax1m = false
let checked_LAmin1m = false
let checked_LA901m = false
let checked_LA101m = false

const DeviceDetail = props => {

  
  const { deviceId } = useParams()
  const { t, fetchDeviceById, isFetching } = props

  const [justOnce, setJustOnce] = useState(true)
  const [dba_1min, setDba_1min] = useState(0)
  const [dba_1min_min, setDba_1min_min] = useState(0)
  const [dba_1min_max, setDba_1min_max] = useState(0)
  const [value, setValue] = useState(new Date())
  const [fetchingData, setFetchingData] = useState(false)
  const [datos, setDatos] = useState([])
  const [datosRT, setDatosRT] = useState([])
  const [datosCSV, setDatosCSV] =useState([])
  const [parsedData_LAeq1m_array, setParsedData_LAeq1m_array] = useState([])
  const [parsedData_LAmin1m_array, setParsedData_LAmin1m_array] = useState([])
  const [parsedData_LAmax1m_array, setParsedData_LAmax1m_array] = useState([])
  const [parsedData_LA901m_array, setParsedData_LA901m_array] = useState([])
  const [parsedData_LA101m_array, setParsedData_LA101m_array] = useState([])
  const [parsedData_LAeq_complete_array, setParsedData_LAeq_complete_array] = useState([])
  
  let  [,setState]=useState()



  const paintSelectedData = () => {

    if (parsedData_LAeq1m_array.length > 0) {

      let selectedParsedData
      let selectedParsedData_LAeq1m
      let selectedParsedData_LAmin1m
      let selectedParsedData_LAmax1m
      let selectedParsedData_LA901m
      let selectedParsedData_LA101m
      let selectedParsedData_LAeq30m
      let selectedParsedData_LAmin30m
      let selectedParsedData_LAmax30m
      let selectedParsedData_LA9030m
      let selectedParsedData_LA1030m
      let selectedParsedData_LAeq1h
      let selectedParsedData_LAmin1h
      let selectedParsedData_LAmax1h
      let selectedParsedData_LA901h
      let selectedParsedData_LA101h
  
      // la primera vez que pintamos los datos, calculamos las integraciones para espacios temporales de más de 1 minuto
      
        let integratedData = new Array()
        let integratedData_min = new Array()
        let integratedData_max = new Array()
        let integratedData_90 = new Array()
        let integratedData_10 = new Array()
        let integratedData_complete = new Array()
        let integratedData_min_complete = new Array()
        let integratedData_max_complete = new Array()
        let integratedData_90_complete = new Array()
        let integratedData_10_complete = new Array()


        // Integración para 1 MINUTO


        reparsedData_LAeq1m_array = new Array()
      
        if (checked_LAeq1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAeq,1m",
            "color": "hsl(360, 100%, 0%)",
            "data": parsedData_LAeq1m_array[0].data
          })
        }

        if (checked_LAmin1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmin,1m",
            "color": "hsl(360, 100%, 50%)",
            "data": parsedData_LAmin1m_array[0].data
          })
        }

        if (checked_LAmax1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmax,1m",
            "color": "hsl(360, 100%, 50%)",
            "data": parsedData_LAmax1m_array[0].data
          })
        }

        if (checked_LA901m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA90,1m",
            "color": "hsl(360, 100%, 50%)",
            "data": parsedData_LA901m_array[0].data
          })
        }

        if (checked_LA101m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA10,1m",
            "color": "hsl(360, 100%, 50%)",
            "data": parsedData_LA101m_array[0].data
          })
        }

              
        parseadoData_LAeq1m_array = reparsedData_LAeq1m_array
        
  


        // Integración para 5 MINUTOS

        integratedData = new Array()
        integratedData_min = new Array()
        integratedData_max = new Array()
        integratedData_90 = new Array()
        integratedData_10 = new Array()
        integratedData_complete = new Array()
        integratedData_min_complete = new Array()
        integratedData_max_complete = new Array()
        integratedData_90_complete = new Array()
        integratedData_10_complete = new Array()
        
  
        for (var i = 0; i < 288; i++) {
        
          // asumimos que tenemos 1440 datos
          console.log("LONGITUD DATOS PARA 5MIN",parsedData_LAeq1m_array[0].data.length)
          let numeroDatos = 0
          let datoAcumulado = 0
          let datoAcumulado90 = 0
          let datoAcumulado10 = 0
          let maximo = 0
          let minimo = 1000
          for (var j = 0; j < 5; j++) {
            if (parsedData_LAeq1m_array[0].data[(5*i)+j].y == 0) {
              // si el dato está a cero no hacemos nada
            } else {
              datoAcumulado += 10 ** (parsedData_LAeq1m_array[0].data[(5*i)+j].y/10)
              datoAcumulado90 += 10 ** (parsedData_LA901m_array[0].data[(5*i)+j].y/10)
              datoAcumulado10 += 10 ** (parsedData_LA101m_array[0].data[(5*i)+j].y/10)
              if (parsedData_LAmax1m_array[0].data[(5*i)+j].y > maximo) {
                maximo = parsedData_LAmax1m_array[0].data[(5*i)+j].y
              }
              if (parsedData_LAmin1m_array[0].data[(5*i)+j].y < minimo) {
                minimo = parsedData_LAmin1m_array[0].data[(5*i)+j].y
              }
              numeroDatos ++
            }
          }
  
          integratedData.push({"x": '', "y": ''})
          integratedData_min.push({"x": '', "y": ''})
          integratedData_max.push({"x": '', "y": ''})
          integratedData_90.push({"x": '', "y": ''})
          integratedData_10.push({"x": '', "y": ''})
          integratedData[i].x = parsedData_LAeq1m_array[0].data[i*5].x
          integratedData_min[i].x = parsedData_LAmin1m_array[0].data[i*5].x
          integratedData_max[i].x = parsedData_LAmax1m_array[0].data[i*5].x
          integratedData_90[i].x = parsedData_LA901m_array[0].data[i*5].x
          integratedData_10[i].x = parsedData_LA101m_array[0].data[i*5].x
          
          if (datoAcumulado == 0) {
            integratedData[i].y = 0
            integratedData_min[i].y = 0
            integratedData_max[i].y = 0
            integratedData_90[i].y = 0
            integratedData_10[i].y = 0
          } else {
            integratedData[i].y = Math.round(10*(10*Math.log10(datoAcumulado/numeroDatos)))/10
            integratedData_min[i].y = minimo
            integratedData_max[i].y = maximo
            integratedData_90[i].y = Math.round(10*(10*Math.log10(datoAcumulado90/numeroDatos)))/10
            integratedData_10[i].y = Math.round(10*(10*Math.log10(datoAcumulado10/numeroDatos)))/10
          }
  
          numeroDatos = 0
          datoAcumulado = 0
          datoAcumulado90 = 0
          datoAcumulado10 = 0
        }
  
        reparsedData_LAeq1m_array = new Array()
      
        if (checked_LAeq1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAeq,5m",
            "color": "hsl(360, 100%, 0%)",
            "data": integratedData
          })
        }

        if (checked_LAmin1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmin,5m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_min
          })
        }

        if (checked_LAmax1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmax,5m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_max
          })
        }

        if (checked_LA901m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA90,5m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_90
          })
        }

        if (checked_LA101m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA10,5m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_10
          })
        }

  
        parsedData_LAeq5m_array = reparsedData_LAeq1m_array





        // Integración para 15 MINUTOS

        integratedData = new Array()
        integratedData_min = new Array()
        integratedData_max = new Array()
        integratedData_90 = new Array()
        integratedData_10 = new Array()
        integratedData_complete = new Array()
        integratedData_min_complete = new Array()
        integratedData_max_complete = new Array()
        integratedData_90_complete = new Array()
        integratedData_10_complete = new Array()
        
  
        for (var i = 0; i < 96; i++) {
        
          // asumimos que tenemos 1440 datos
          console.log("LONGITUD DATOS PARA 15MIN",parsedData_LAeq1m_array[0].data.length)
          let numeroDatos = 0
          let datoAcumulado = 0
          let datoAcumulado90 = 0
          let datoAcumulado10 = 0
          let maximo = 0
          let minimo = 1000
          for (var j = 0; j < 15; j++) {
            if (parsedData_LAeq1m_array[0].data[(15*i)+j].y == 0) {
              // si el dato está a cero no hacemos nada
            } else {
              datoAcumulado += 10 ** (parsedData_LAeq1m_array[0].data[(15*i)+j].y/10)
              datoAcumulado90 += 10 ** (parsedData_LA901m_array[0].data[(15*i)+j].y/10)
              datoAcumulado10 += 10 ** (parsedData_LA101m_array[0].data[(15*i)+j].y/10)
              if (parsedData_LAmax1m_array[0].data[(15*i)+j].y > maximo) {
                maximo = parsedData_LAmax1m_array[0].data[(15*i)+j].y
              }
              if (parsedData_LAmin1m_array[0].data[(15*i)+j].y < minimo) {
                minimo = parsedData_LAmin1m_array[0].data[(15*i)+j].y
              }
              numeroDatos ++
            }
          }
  
          integratedData.push({"x": '', "y": ''})
          integratedData_min.push({"x": '', "y": ''})
          integratedData_max.push({"x": '', "y": ''})
          integratedData_90.push({"x": '', "y": ''})
          integratedData_10.push({"x": '', "y": ''})
          integratedData[i].x = parsedData_LAeq1m_array[0].data[i*15].x
          integratedData_min[i].x = parsedData_LAmin1m_array[0].data[i*15].x
          integratedData_max[i].x = parsedData_LAmax1m_array[0].data[i*15].x
          integratedData_90[i].x = parsedData_LA901m_array[0].data[i*15].x
          integratedData_10[i].x = parsedData_LA101m_array[0].data[i*15].x
          
          if (datoAcumulado == 0) {
            integratedData[i].y = 0
            integratedData_min[i].y = 0
            integratedData_max[i].y = 0
            integratedData_90[i].y = 0
            integratedData_10[i].y = 0
          } else {
            integratedData[i].y = Math.round(10*(10*Math.log10(datoAcumulado/numeroDatos)))/10
            integratedData_min[i].y = minimo
            integratedData_max[i].y = maximo
            integratedData_90[i].y = Math.round(10*(10*Math.log10(datoAcumulado90/numeroDatos)))/10
            integratedData_10[i].y = Math.round(10*(10*Math.log10(datoAcumulado10/numeroDatos)))/10
          }
  
          numeroDatos = 0
          datoAcumulado = 0
          datoAcumulado90 = 0
          datoAcumulado10 = 0
        }
  
        reparsedData_LAeq1m_array = new Array()
      
        if (checked_LAeq1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAeq,15m",
            "color": "hsl(360, 100%, 0%)",
            "data": integratedData
          })
        }

        if (checked_LAmin1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmin,15m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_min
          })
        }

        if (checked_LAmax1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmax,15m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_max
          })
        }

        if (checked_LA901m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA90,15m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_90
          })
        }

        if (checked_LA101m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA10,15m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_10
          })
        }

  
        parsedData_LAeq15m_array = reparsedData_LAeq1m_array





        // Integración para 30 MINUTOS

        integratedData = new Array()
        integratedData_min = new Array()
        integratedData_max = new Array()
        integratedData_90 = new Array()
        integratedData_10 = new Array()
        integratedData_complete = new Array()
        integratedData_min_complete = new Array()
        integratedData_max_complete = new Array()
        integratedData_90_complete = new Array()
        integratedData_10_complete = new Array()
        
  
        for (var i = 0; i < 48; i++) {
        
          // asumimos que tenemos 1440 datos
          console.log("LONGITUD DATOS PARA 30MIN",parsedData_LAeq1m_array[0].data.length)
          let numeroDatos = 0
          let datoAcumulado = 0
          let datoAcumulado90 = 0
          let datoAcumulado10 = 0
          let maximo = 0
          let minimo = 1000
          for (var j = 0; j < 30; j++) {
            if (parsedData_LAeq1m_array[0].data[(30*i)+j].y == 0) {
              // si el dato está a cero no hacemos nada
            } else {
              datoAcumulado += 10 ** (parsedData_LAeq1m_array[0].data[(30*i)+j].y/10)
              datoAcumulado90 += 10 ** (parsedData_LA901m_array[0].data[(30*i)+j].y/10)
              datoAcumulado10 += 10 ** (parsedData_LA101m_array[0].data[(30*i)+j].y/10)
              if (parsedData_LAmax1m_array[0].data[(30*i)+j].y > maximo) {
                maximo = parsedData_LAmax1m_array[0].data[(30*i)+j].y
              }
              if (parsedData_LAmin1m_array[0].data[(30*i)+j].y < minimo) {
                minimo = parsedData_LAmin1m_array[0].data[(30*i)+j].y
              }
              numeroDatos ++
            }
          }
  
          integratedData.push({"x": '', "y": ''})
          integratedData_min.push({"x": '', "y": ''})
          integratedData_max.push({"x": '', "y": ''})
          integratedData_90.push({"x": '', "y": ''})
          integratedData_10.push({"x": '', "y": ''})
          integratedData[i].x = parsedData_LAeq1m_array[0].data[i*30].x
          integratedData_min[i].x = parsedData_LAmin1m_array[0].data[i*30].x
          integratedData_max[i].x = parsedData_LAmax1m_array[0].data[i*30].x
          integratedData_90[i].x = parsedData_LA901m_array[0].data[i*30].x
          integratedData_10[i].x = parsedData_LA101m_array[0].data[i*30].x
          
          if (datoAcumulado == 0) {
            integratedData[i].y = 0
            integratedData_min[i].y = 0
            integratedData_max[i].y = 0
            integratedData_90[i].y = 0
            integratedData_10[i].y = 0
          } else {
            integratedData[i].y = Math.round(10*(10*Math.log10(datoAcumulado/numeroDatos)))/10
            integratedData_min[i].y = minimo
            integratedData_max[i].y = maximo
            integratedData_90[i].y = Math.round(10*(10*Math.log10(datoAcumulado90/numeroDatos)))/10
            integratedData_10[i].y = Math.round(10*(10*Math.log10(datoAcumulado10/numeroDatos)))/10
          }
  
          numeroDatos = 0
          datoAcumulado = 0
          datoAcumulado90 = 0
          datoAcumulado10 = 0
        }
  
        reparsedData_LAeq1m_array = new Array()
      
        if (checked_LAeq1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAeq,30m",
            "color": "hsl(360, 100%, 0%)",
            "data": integratedData
          })
        }

        if (checked_LAmin1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmin,30m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_min
          })
        }

        if (checked_LAmax1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmax,30m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_max
          })
        }

        if (checked_LA901m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA90,30m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_90
          })
        }

        if (checked_LA101m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA10,30m",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_10
          })
        }

  
        parsedData_LAeq30m_array = reparsedData_LAeq1m_array//integratedData_complete
        //parsedData_LAmin30m_array = integratedData_min_complete
        //parsedData_LAmax30m_array = integratedData_max_complete
        //parsedData_LA9030m_array = integratedData_90_complete
        //parsedData_LA1030m_array = integratedData_10_complete

  
  
        // Integración para 1 HORA
  
        integratedData = new Array()
        integratedData_min = new Array()
        integratedData_max = new Array()
        integratedData_90 = new Array()
        integratedData_10 = new Array()
        integratedData_complete = new Array()
        integratedData_min_complete = new Array()
        integratedData_max_complete = new Array()
        integratedData_90_complete = new Array()
        integratedData_10_complete = new Array()
        
  
        for (var i = 0; i < 24; i++) {
        
          let numeroDatos = 0
          let datoAcumulado = 0
          let datoAcumulado90 = 0
          let datoAcumulado10 = 0
          let maximo = 0
          let minimo = 1000
          for (var j = 0; j < 60; j++) {
            if (parsedData_LAeq1m_array[0].data[(60*i)+j].y == 0) {
              // si el dato está a cero no hacemos nada
            } else {
              datoAcumulado += 10 ** (parsedData_LAeq1m_array[0].data[(60*i)+j].y/10)
              datoAcumulado90 += 10 ** (parsedData_LA901m_array[0].data[(60*i)+j].y/10)
              datoAcumulado10 += 10 ** (parsedData_LA101m_array[0].data[(60*i)+j].y/10)
              if (parsedData_LAmax1m_array[0].data[(60*i)+j].y > maximo) {
                maximo = parsedData_LAmax1m_array[0].data[(60*i)+j].y
              }
              if (parsedData_LAmin1m_array[0].data[(60*i)+j].y < minimo) {
                minimo = parsedData_LAmin1m_array[0].data[(60*i)+j].y
              }
              numeroDatos ++
            }
            
          }
          
  
          integratedData.push({"x": '', "y": ''})
          integratedData_min.push({"x": '', "y": ''})
          integratedData_max.push({"x": '', "y": ''})
          integratedData_90.push({"x": '', "y": ''})
          integratedData_10.push({"x": '', "y": ''})
          integratedData[i].x = parsedData_LAeq1m_array[0].data[i*60].x
          integratedData_min[i].x = parsedData_LAmin1m_array[0].data[i*60].x
          integratedData_max[i].x = parsedData_LAmax1m_array[0].data[i*60].x
          integratedData_90[i].x = parsedData_LA901m_array[0].data[i*60].x
          integratedData_10[i].x = parsedData_LA101m_array[0].data[i*60].x
          
          if (datoAcumulado == 0) {
            integratedData[i].y = 0
            integratedData_min[i].y = 0
            integratedData_max[i].y = 0
            integratedData_90[i].y = 0
            integratedData_10[i].y = 0
          } else {
            integratedData[i].y = Math.round(10*(10*Math.log10(datoAcumulado/numeroDatos)))/10
            integratedData_min[i].y = minimo
            integratedData_max[i].y = maximo
            integratedData_90[i].y = Math.round(10*(10*Math.log10(datoAcumulado90/numeroDatos)))/10
            integratedData_10[i].y = Math.round(10*(10*Math.log10(datoAcumulado10/numeroDatos)))/10
          }
  
          numeroDatos = 0
          datoAcumulado = 0
          datoAcumulado90 = 0
          datoAcumulado10 = 0
  
        }



        reparsedData_LAeq1m_array = new Array()
      
        if (checked_LAeq1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAeq,1h",
            "color": "hsl(360, 100%, 0%)",
            "data": integratedData
          })
        }

        if (checked_LAmin1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmin,1h",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_min
          })
        }

        if (checked_LAmax1m) {
          reparsedData_LAeq1m_array.push({
            "id": "LAmax,1h",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_max
          })
        }

        if (checked_LA901m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA90,1h",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_90
          })
        }

        if (checked_LA101m) {
          reparsedData_LAeq1m_array.push({
            "id": "LA10,1h",
            "color": "hsl(360, 100%, 50%)",
            "data": integratedData_10
          })
        }

              
        parsedData_LAeq1h_array = reparsedData_LAeq1m_array//integratedData_complete
        //parsedData_LAmin1h_array = integratedData_min_complete
        //parsedData_LAmax1h_array = integratedData_max_complete
        //parsedData_LA901h_array = integratedData_90_complete
        //parsedData_LA101h_array = integratedData_10_complete
        

  // Una vez realizados todos los cálculos de integraciones temporales
  // pasamos a la selección de ventana temporal
  
      switch(selectedWindowTime) {
        case "24h": 
          switch(selectedLA) {
            case "1m":
              setDatos(parseadoData_LAeq1m_array)
              break
            case "5m":
                setDatos(parsedData_LAeq5m_array)
                break
            case "15m":
                setDatos(parsedData_LAeq15m_array)
                break
            case "30m":
              setDatos(parsedData_LAeq30m_array)
              break
            case "1h":
              setDatos(parsedData_LAeq1h_array)
              break
          }
          break


        case "0-6h":
          console.log("PASO POR AQUI")
          switch(selectedLA) {
            case "1m":
              for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(0, Math.ceil(parseadoData_LAeq1m_array[j].data.length/4))
              }   
              setDatos(parseadoData_LAeq1m_array)
              break
  
            case "5m":
              for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(0, Math.ceil(parsedData_LAeq5m_array[j].data.length/4))
              }   
              setDatos(parsedData_LAeq5m_array)
              break
  
            case "15m":
              for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(0, Math.ceil(parsedData_LAeq15m_array[j].data.length/4))
              }   
              setDatos(parsedData_LAeq15m_array)
              break
                
            case "30m":
              for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(0, Math.ceil(parsedData_LAeq30m_array[j].data.length/4))
              }   
              setDatos(parsedData_LAeq30m_array)
              break
  
            case "1h":
              for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(0, Math.ceil(parsedData_LAeq1h_array[j].data.length/4))
              }   
              setDatos(parsedData_LAeq1h_array)
              break
              
          }
          break 



        case "6-12h":
          console.log("PASO POR AQUI 6-12")
          switch(selectedLA) {
            case "1m":
              for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(Math.ceil(parseadoData_LAeq1m_array[j].data.length/4), Math.ceil(parseadoData_LAeq1m_array[j].data.length/2))
              }   
              setDatos(parseadoData_LAeq1m_array)
              break

            case "5m":
              for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(Math.ceil(parsedData_LAeq5m_array[j].data.length/4), Math.ceil(parsedData_LAeq5m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq5m_array)
              console.log(parsedData_LAeq5m_array)
          
              break

            case "15m":
              for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(Math.ceil(parsedData_LAeq15m_array[j].data.length/4), Math.ceil(parsedData_LAeq15m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq15m_array)
              break
              
            case "30m":
              for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(Math.ceil(parsedData_LAeq30m_array[j].data.length/4), Math.ceil(parsedData_LAeq30m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq30m_array)
              break

            case "1h":
              for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(Math.ceil(parsedData_LAeq1h_array[j].data.length/4), Math.ceil(parsedData_LAeq1h_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq1h_array)
              break
            
          }
          break 


    
  
        case "0-12h":
          console.log("PASO POR AQUI")
          switch(selectedLA) {
            case "1m":
              for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(0, Math.ceil(parseadoData_LAeq1m_array[j].data.length/2))
              }   
              setDatos(parseadoData_LAeq1m_array)
              break

            case "5m":
              for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(0, Math.ceil(parsedData_LAeq5m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq5m_array)
              break

            case "15m":
              for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(0, Math.ceil(parsedData_LAeq15m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq15m_array)
              break
              
            case "30m":
              for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(0, Math.ceil(parsedData_LAeq30m_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq30m_array)
              break

            case "1h":
              for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(0, Math.ceil(parsedData_LAeq1h_array[j].data.length/2))
              }   
              setDatos(parsedData_LAeq1h_array)
              break
            
          }
          break 


        case "12-16h":
          switch(selectedLA) {
            case "1m":
              console.log("PASO POR AQUI")
              for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(Math.ceil(parseadoData_LAeq1m_array[j].data.length/2), 3*parseadoData_LAeq1m_array[j].data.length/4)
              }   
              setDatos(parseadoData_LAeq1m_array)
              break
  
            case "5m":
              for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(Math.ceil(parsedData_LAeq5m_array[j].data.length/2), 3*parsedData_LAeq5m_array[j].data.length/4)
              }   
              setDatos(parsedData_LAeq5m_array)
              break
  
            case "15m":
              for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(Math.ceil(parsedData_LAeq15m_array[j].data.length/2), 3*parsedData_LAeq15m_array[j].data.length/4)
              }   
              setDatos(parsedData_LAeq15m_array)
              break
              
            case "30m":
              for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(Math.ceil(parsedData_LAeq30m_array[j].data.length/2), 3*parsedData_LAeq30m_array[j].data.length/4)
              }   
              setDatos(parsedData_LAeq30m_array)
              break
              
            case "1h":
              for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(Math.ceil(parsedData_LAeq1h_array[j].data.length/2), 3*parsedData_LAeq1h_array[j].data.length/4)
              }   
              setDatos(parsedData_LAeq1h_array)
              break
              
          }
          break 


        case "16-24h":
            switch(selectedLA) {
              case "1m":
                console.log("PASO POR AQUI")
                for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                  parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(Math.ceil(3*parseadoData_LAeq1m_array[j].data.length/4), parseadoData_LAeq1m_array[j].data.length)
                }   
                setDatos(parseadoData_LAeq1m_array)
                break
  
              case "5m":
                for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                  parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(Math.ceil(3*parsedData_LAeq5m_array[j].data.length/4), parsedData_LAeq5m_array[j].data.length)
                }   
                setDatos(parsedData_LAeq5m_array)
                break
  
              case "15m":
                for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                  parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(Math.ceil(3*parsedData_LAeq15m_array[j].data.length/4), parsedData_LAeq15m_array[j].data.length)
                }   
                setDatos(parsedData_LAeq15m_array)
                break
              
              case "30m":
                for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                  parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(Math.ceil(3*parsedData_LAeq30m_array[j].data.length/4), parsedData_LAeq30m_array[j].data.length)
                }   
                setDatos(parsedData_LAeq30m_array)
                break
              
              case "1h":
                for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                  parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(Math.ceil(3*parsedData_LAeq1h_array[j].data.length/4), parsedData_LAeq1h_array[j].data.length)
                }   
                setDatos(parsedData_LAeq1h_array)
                break
              
            }
            break 
  


        case "12-24h":
          switch(selectedLA) {
            case "1m":
              console.log("PASO POR AQUI")
              for (var j = 0; j < parseadoData_LAeq1m_array.length; j++) {
                parseadoData_LAeq1m_array[j].data = parseadoData_LAeq1m_array[j].data.slice(Math.ceil(parseadoData_LAeq1m_array[j].data.length/2), parseadoData_LAeq1m_array[j].data.length)
              }   
              setDatos(parseadoData_LAeq1m_array)
              break

            case "5m":
              for (var j = 0; j < parsedData_LAeq5m_array.length; j++) {
                parsedData_LAeq5m_array[j].data = parsedData_LAeq5m_array[j].data.slice(Math.ceil(parsedData_LAeq5m_array[j].data.length/2), parsedData_LAeq5m_array[j].data.length)
              }   
              setDatos(parsedData_LAeq5m_array)
              break

            case "15m":
              for (var j = 0; j < parsedData_LAeq15m_array.length; j++) {
                parsedData_LAeq15m_array[j].data = parsedData_LAeq15m_array[j].data.slice(Math.ceil(parsedData_LAeq15m_array[j].data.length/2), parsedData_LAeq15m_array[j].data.length)
              }   
              setDatos(parsedData_LAeq15m_array)
              break
            
            case "30m":
              for (var j = 0; j < parsedData_LAeq30m_array.length; j++) {
                parsedData_LAeq30m_array[j].data = parsedData_LAeq30m_array[j].data.slice(Math.ceil(parsedData_LAeq30m_array[j].data.length/2), parsedData_LAeq30m_array[j].data.length)
              }   
              setDatos(parsedData_LAeq30m_array)
              break
            
            case "1h":
              for (var j = 0; j < parsedData_LAeq1h_array.length; j++) {
                parsedData_LAeq1h_array[j].data = parsedData_LAeq1h_array[j].data.slice(Math.ceil(parsedData_LAeq1h_array[j].data.length/2), parsedData_LAeq1h_array[j].data.length)
              }   
              setDatos(parsedData_LAeq1h_array)
              break
            
          }
          break 
      }

    }
    
  }


  const handleChangeSelect2 = (selectedOption) => {
    switch(selectedOption.value) {
      case "1m": selectedLA = "1m"
                  break
      case "5m": selectedLA = "5m"
                  break
      case "15m": selectedLA = "15m"
                  break
      case "30m": selectedLA = "30m"
                  break
      case "1h":  selectedLA = "1h"
                  break
    }
    paintSelectedData()
  }


  const handleChangeSelect = (selectedOption) => {

    switch(selectedOption.value) {
      case "24h": selectedWindowTime = "24h"
                  break
      case "0-12h": selectedWindowTime = "0-12h"
                    break
      case "6-12h": selectedWindowTime = "6-12h"
                    break
      case "0-6h": selectedWindowTime = "0-6h"
                    break
      case "12-16h": selectedWindowTime = "12-16h"
                    break
      case "16-24h":  selectedWindowTime = "16-24h"
                    break
      case "12-24h":  selectedWindowTime = "12-24h"
                      break
    }

    paintSelectedData()
  }





  const handleChangeCheckBox_LAeq1m = (event) => {
    checked_LAeq1m = event.target.checked
    paintSelectedData()
  }

  const handleChangeCheckBox_LAmax1m = (event) => {
    checked_LAmax1m = event.target.checked
    paintSelectedData()
  }

  const handleChangeCheckBox_LAmin1m = (event) => {
    checked_LAmin1m = event.target.checked
    paintSelectedData()
  }

  const handleChangeCheckBox_LA901m = (event) => {
    checked_LA901m = event.target.checked
    paintSelectedData()
  }

  const handleChangeCheckBox_LA101m = (event) => {
    checked_LA101m = event.target.checked
    paintSelectedData()
  }




  function calculateLden() {  // calculamos Ld, Le, Ln

    // tenemos que solicitar los datos del día completo, para calcular Ld (7:00am a 7:00pm) y Le (7:00pm a 11:00pm)
    // para calcular Ln (23:00pm a 7:am), necesitamos solicitar los datos del día posterior

    // solicitamos primero los datos del día

  
    setFetchingData(true)
    let myID = props.device.chipID
    var request = fetch('https://iubrir36s6.execute-api.us-west-2.amazonaws.com/default/fetchDynamoDBdata?ID='+myID+'&timestamp='+value.getTime().toString(),{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-api-key': 'zgIVUT2fX76zeEXbpzp4p3DTJZ59pEtK9wpTm2A8'
            }
        })
        .then(response => response.json())
        .then((data) => { 
          let holes = 0
          if (Object.keys(data).length === 0) {
            setParsedData_LAeq1m_array([])
            setParsedData_LAmin1m_array([])
            setParsedData_LAmax1m_array([])
            setFetchingData(false)
          } else {
            parsedData = new Array()
            parsedData_min = new Array()
            parsedData_max = new Array()
            parsedData_90 = new Array()
            parsedData_10 = new Array()
            parsedDataComplete = new Array()
            parsedDataComplete_min = new Array()
            parsedDataComplete_max = new Array()
            parsedDataComplete_90 = new Array()
            parsedDataComplete_10 = new Array()
            parsedData_LAeq1m = new Array()
            parsedData_LAmin1m = new Array()
            parsedData_LAmax1m = new Array()
            parsedData_LA901m = new Array()
            parsedData_LA101m = new Array()
            parsedDataCSV = new Array()
            let fecha
            let dia
            let mes
            let hora
            let horaMas
            let minuto
            let minutoMas
            let cadenaFecha
            let cadenaFechaCorta
            let num_ceros
            let missedHoles = new Array()

            let timestampNext
            let timestampNow

            //parsedDataCSV.push(["LAeq1m", "LAmin", "LAmax", "timestamp", "date"])
              
            dia = new Date(data[0].timestamp).getDate()
            mes = new Date(data[0].timestamp).getMonth() + 1
            csvDia = String(dia)
            csvMes = String(mes)
            csvYear = String(new Date(data[0].timestamp).getFullYear())
              
              
            for (var i = 0; i < Object.keys(data).length; i++) {
              //console.log(data[i].timestamp)
              fecha = new Date(data[i].timestamp)
              hora = fecha.getHours()
              minuto = fecha.getMinutes()
              
              cadenaFechaCorta = hora + ":" + minuto
              cadenaFecha = hora + ":" + minuto + " dia " + dia + " mes " + mes


              if ( (props.device.uuid == '20m0000') || (props.device.uuid == '20m0001') || (props.device.uuid == '20m0003') 
                    || (props.device.uuid == '20m0004') || (props.device.uuid == '20m0005') || (props.device.uuid == '20m0006')
                    || (props.device.uuid == '20m0007') || (props.device.uuid == '20m0008') || (props.device.uuid == '20m0009')
                    || (props.device.uuid == '20m0010') || (props.device.uuid == '20m0011') || (props.device.uuid == '20m0012')
                    || (props.device.uuid == '20m0013') ) 
              
                    {
                

                      parsedData.push({"x": '', "y": ''})
                      parsedData[i].x = data[i].timestamp     //cadenaFecha //new Date(data[i].timestamp).toTimeString()
                      parsedData[i].y = ( -0.0006987*(parseFloat(data[i].dBA)*parseFloat(data[i].dBA)) + 1.057*parseFloat(data[i].dBA) - 6.529 ).toFixed(1)

                      parsedData_min.push({"x": '', "y": ''})
                      parsedData_min[i].x = data[i].timestamp
                      parsedData_min[i].y = ( -0.0006987*(parseFloat(data[i].dBA_min)*parseFloat(data[i].dBA_min)) + 1.057*parseFloat(data[i].dBA_min) - 6.529 ).toFixed(1)

                      parsedData_max.push({"x": '', "y": ''})
                      parsedData_max[i].x = data[i].timestamp
                      parsedData_max[i].y = ( -0.0006987*(parseFloat(data[i].dBA_max)*parseFloat(data[i].dBA_max)) + 1.057*parseFloat(data[i].dBA_max) - 6.529 ).toFixed(1)

                      parsedDataCSV.push([parsedData[i].y, parsedData_min[i].y, parsedData_max[i].y, hora, minuto, mes, dia, fecha, data[i].timestamp])
                      csvHeaders = ["LAeq1m", "LAmin", "LAmax", "hour", "minute", "month", "day", "date", "timestamp"]
                              
              }
              else {

                parsedData.push({"x": '', "y": ''})
                parsedData[i].x = data[i].timestamp     //cadenaFecha //new Date(data[i].timestamp).toTimeString()
                parsedData[i].y = ( -0.0006987*(parseFloat(data[i].dBA)*parseFloat(data[i].dBA)) + 1.057*parseFloat(data[i].dBA) - 6.529 ).toFixed(1)

                parsedData_min.push({"x": '', "y": ''})
                parsedData_min[i].x = data[i].timestamp
                parsedData_min[i].y = ( -0.0006987*(parseFloat(data[i].min)*parseFloat(data[i].min)) + 1.057*parseFloat(data[i].min) - 6.529 ).toFixed(1)

                parsedData_max.push({"x": '', "y": ''})
                parsedData_max[i].x = data[i].timestamp
                parsedData_max[i].y = ( -0.0006987*(parseFloat(data[i].max)*parseFloat(data[i].max)) + 1.057*parseFloat(data[i].max) - 6.529 ).toFixed(1)

                parsedData_90.push({"x": '', "y": ''})
                parsedData_90[i].x = data[i].timestamp
                parsedData_90[i].y = ( -0.0006987*(parseFloat(data[i].L90)*parseFloat(data[i].L90)) + 1.057*parseFloat(data[i].L90) - 6.529 ).toFixed(1)

                parsedData_10.push({"x": '', "y": ''})
                parsedData_10[i].x = data[i].timestamp
                parsedData_10[i].y = ( -0.0006987*(parseFloat(data[i].L10)*parseFloat(data[i].L10)) + 1.057*parseFloat(data[i].L10) - 6.529 ).toFixed(1)

                parsedDataCSV.push([parsedData[i].y, parsedData_min[i].y, parsedData_max[i].y, parsedData_90[i].y, parsedData_10[i].y, hora, minuto, mes, dia, fecha, data[i].timestamp])
                csvHeaders = ["LAeq1m", "LAmin", "LAmax", "LA90", "LA10", "hour", "minute", "month", "day", "date", "timestamp"]        

              }
              
              
            }

            
            // Tenemos un array de datos, que deberían ser 1440
            // Vamos a ir completando el array final de datos

            // necesitamos conocer el día exacto en el que empieza nuestro array
            let myDate = new Date(parsedData[0].x)
            let myDate2 = myDate - (myDate.getHours()*3600000) - (myDate.getMinutes()*60000)
            //console.log("FECHA INICIAL", myDate.toString())

            // COMPROBACIÓN DE CONSISTENCIA DE LOS DATOS DE PARSED_DATA
            for (var i = 0; i < parsedData.length-1; i++) {
              // no deben existir dos datos en el mismo minuto, supone un duplicado
              if ( ((new Date(parsedData[i].x).getHours())*60 + new Date(parsedData[i].x).getMinutes()) == 
                      ((new Date(parsedData[i+1].x).getHours())*60 + new Date(parsedData[i+1].x).getMinutes()) ) {
                parsedData.splice(i,1) // eliminamos el dato "duplicado"
              }
            }
            
            
            let myMinute = 0
            for (var i = 0; i < 1440; i++) {

              // si quedan datos que procesar
              if (parsedData.length > 0) {
                parsedDataComplete.push({"x": '', "y": ''})
                parsedDataComplete_min.push({"x": '', "y": ''})
                parsedDataComplete_max.push({"x": '', "y": ''})
                parsedDataComplete_90.push({"x": '', "y": ''})
                parsedDataComplete_10.push({"x": '', "y": ''})

                // Vamos a crear un Array de 1440 datos

                //console.log("MINUTO DATO",( (new Date(parsedData[0].x).getHours())*60 + new Date(parsedData[0].x).getMinutes() ))
                //console.log("MINUTO LOOP", myMinute)
              
                
                // Si nuestro siguiente dato no se corresponde con el minuto en el que estamos, rellenaremos con cero
                if ( ( (new Date(parsedData[0].x).getHours())*60 + new Date(parsedData[0].x).getMinutes() ) != myMinute) {
                  parsedDataComplete[i].x = myDate2
                  parsedDataComplete[i].y = 0
                  parsedDataComplete_min[i].x = myDate2
                  parsedDataComplete_min[i].y = 0
                  parsedDataComplete_max[i].x = myDate2
                  parsedDataComplete_max[i].y = 0
                  parsedDataComplete_90[i].x = myDate2
                  parsedDataComplete_90[i].y = 0
                  parsedDataComplete_10[i].x = myDate2
                  parsedDataComplete_10[i].y = 0

                  
                } else {
                  // Si existe correspondencia entre el minuto en el que estamos y el del dato, lo introducimos
                  parsedDataComplete[i].x = myDate2
                  parsedDataComplete[i].y = parsedData[0].y
                  parsedDataComplete_min[i].x = myDate2
                  parsedDataComplete_min[i].y = parsedData_min[0].y
                  parsedDataComplete_max[i].x = myDate2
                  parsedDataComplete_max[i].y = parsedData_max[0].y

                  if ( !( (props.device.uuid == '20m0000') || (props.device.uuid == '20m0001') || (props.device.uuid == '20m0003') 
                    || (props.device.uuid == '20m0004') || (props.device.uuid == '20m0005') || (props.device.uuid == '20m0006')
                    || (props.device.uuid == '20m0007') || (props.device.uuid == '20m0008') || (props.device.uuid == '20m0009')
                    || (props.device.uuid == '20m0010') || (props.device.uuid == '20m0011') || (props.device.uuid == '20m0012')
                    || (props.device.uuid == '20m0013') ) ) 
              
                    {
                      parsedDataComplete_90[i].x = myDate2
                      parsedDataComplete_90[i].y = parsedData_90[0].y
                      parsedDataComplete_10[i].x = myDate2
                      parsedDataComplete_10[i].y = parsedData_10[0].y
                    }
                  
                  
                  // ahora desechamos el dato del Array original
                  parsedData.shift()
                  parsedData_min.shift()
                  parsedData_max.shift()
                  
                  
                  parsedData_90.shift()
                  parsedData_10.shift()



                }

                myDate2 = myDate2 + 60000 // pasamos la fecha al minuto siguiente
                myMinute += 1 // añadimos un minuto más

                console.log("DATA COMPLETE", parsedDataComplete)

              } else {

                // si no quedan datos que procesar, debemos rellenar con ceros lo que falte

                let lastZeros = 1440 - parsedDataComplete.length
                console.log("LAST ZEROS", lastZeros)
                for (var j = 0; j < lastZeros; j++) {
                  parsedDataComplete.push({"x": myDate2, "y": 0})
                  parsedDataComplete_min.push({"x": myDate2, "y": 0})
                  parsedDataComplete_max.push({"x": myDate2, "y": 0})
                  parsedDataComplete_90.push({"x": myDate2, "y": 0})
                  parsedDataComplete_10.push({"x": myDate2, "y": 0})
                  myDate2 = myDate2 + 60000 // pasamos la fecha al minuto siguiente
                  myMinute += 1 // añadimos un minuto más
                }

              }
            }

            // transformamos los timestamps en formato legible HH:MM
            let kl
            for (var i = 0; i < parsedDataComplete.length; i++) {
              kl = new Date(parsedDataComplete[i].x).getHours() + ":" + ("0" + new Date(parsedDataComplete[i].x).getMinutes()).substr(-2)
              parsedDataComplete[i].x = kl
              parsedDataComplete_min[i].x = kl
              parsedDataComplete_max[i].x = kl
              parsedDataComplete_90[i].x = kl
              parsedDataComplete_10[i].x = kl
            }

            
            
            // calculamos el Ld
            let acumuladoLd = 0
            for (var k = 0; k < 720;  k++) {
              acumuladoLd += 10 ** (parsedDataComplete[420+k].y/10)
            }

            acumuladoLd = Math.round(10*(10*Math.log10(acumuladoLd/720)))/10
            level_Ld.push({"date": value, "y": acumuladoLd})

            // calculamos el Le
            let acumuladoLe = 0
            
            for (var j = 0; j < 240;  j++) {
              acumuladoLe += 10 ** (parsedDataComplete[1140+j].y/10)
            }
            acumuladoLe = Math.round(10*(10*Math.log10(acumuladoLe/240)))/10
            level_Le.push({"date": value, "y": acumuladoLe})

        


            console.log("parsedDataComplete",parsedDataComplete)
            console.log("parsedDataComplete_min",parsedDataComplete_min)
            console.log("parsedDataComplete_max",parsedDataComplete_max)
            console.log("parsedDataComplete_90",parsedDataComplete_90)
            console.log("parsedDataComplete_10",parsedDataComplete_10)

            console.log("level_Ld", level_Ld)
            console.log("level_Le", level_Le)
            
            console.log("DATOS COMPLETOS", parsedDataComplete.length)
            console.log("MINUTOS COMPLETOS", myMinute)

            
            items.push({
              id: 1,
              group: 2,
              title: level_Ld[0].y.toString(),
              start_time: moment(value).add(7, 'hour'),
              end_time: moment(value).add(19, 'hour')
            })

            items.push({
              id: 2,
              group: 3,
              title: level_Le[0].y.toString(),
              start_time: moment(value).add(19, 'hour'),
              end_time: moment(value).add(23, 'hour')
            })
            
            
            console.log(items)

            setFetchingData(false)
          }
        })
        .catch(error => console.log('Error while fetching:', error))


  }



  function onData(dBA_1min, dBA_1min_min, dBA_1min_max) {
    console.log(dBA_1min)
    console.log(dBA_1min_min)
    console.log(dBA_1min_max)
    for (var i = 0; i < 59; i++) {
      initData_LAeq1m[0].data[i].x = initData_LAeq1m[0].data[i+1].x
      initData_LAeq1m_min[0].data[i].x = initData_LAeq1m_min[0].data[i+1].x
      initData_LAeq1m_max[0].data[i].x = initData_LAeq1m_max[0].data[i+1].x

      initData_LAeq1m[0].data[i].y = initData_LAeq1m[0].data[i+1].y
      initData_LAeq1m_min[0].data[i].y = initData_LAeq1m_min[0].data[i+1].y
      initData_LAeq1m_max[0].data[i].y = initData_LAeq1m_max[0].data[i+1].y
    }
    
    initData_LAeq1m[0].data[59].x = new Date().getHours() + ":" + ("0" + new Date().getMinutes()).substr(-2)
    initData_LAeq1m_min[0].data[59].x = new Date().getHours() + ":" + ("0" + new Date().getMinutes()).substr(-2)
    initData_LAeq1m_max[0].data[59].x = new Date().getHours() + ":" + ("0" + new Date().getMinutes()).substr(-2)
    
    initData_LAeq1m[0].data[59].y = dBA_1min
    initData_LAeq1m_min[0].data[59].y = dBA_1min_min
    initData_LAeq1m_max[0].data[59].y = dBA_1min_max

    reparsedData_RT_array = new Array()
    reparsedData_RT_array.push({
      "id": "LAeq,1m",
      "color": "hsl(360, 100%, 50%)",
      "data": initData_LAeq1m[0].data
    })
    reparsedData_RT_array.push({
      "id": "LA90 1m",
      "color": "hsl(360, 100%, 50%)",
      "data": initData_LAeq1m_min[0].data
    })
    reparsedData_RT_array.push({
      "id": "LA10 1m",
      "color": "hsl(360, 100%, 50%)",
      "data": initData_LAeq1m_max[0].data
    })

    setDatosRT(reparsedData_RT_array);
    
    setState({})
  }


  function onChange(nextValue) {
    setValue(nextValue)
  }


  function onReloadClick() {
    window.location.reload()
  }


  const handleClick = () => {

    setFetchingData(true)
    let myID = props.device.chipID
    console.log(myID)
    console.log("VALUE")
    console.log(value)
    var request = fetch('https://iubrir36s6.execute-api.us-west-2.amazonaws.com/default/fetchDynamoDBdata?ID='+myID+'&timestamp='+value.getTime().toString(),{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-api-key': 'zgIVUT2fX76zeEXbpzp4p3DTJZ59pEtK9wpTm2A8'
                //'Access-Control-Allow-Origin': '*', // Required for CORS support to work
                //'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
                //'Access-Control-Allow-Methods' : 'GET,HEAD,OPTIONS,POST,PUT'
            }
        })
        .then(response => response.json())
        .then((data) => { 
          //console.log(data[0].dBA)
          console.log("NUMERO DATOS", Object.keys(data).length)
          let holes = 0
          if (Object.keys(data).length === 0) {
            setParsedData_LAeq1m_array([])
            setParsedData_LAmin1m_array([])
            setParsedData_LAmax1m_array([])
            reparsedData_LAeq1m_array = new Array()
            reparsedData_LAmin1m_array = new Array()
            reparsedData_LAmax1m_array = new Array()
            reparsedData_RT_array = new Array()
            reparsedData_RT_array.push({
              "id": "LAeq,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": initData_LAeq1m[0].data
            })
            reparsedData_RT_array.push({
              "id": "LAeq,1m min",
              "color": "hsl(360, 100%, 50%)",
              "data": initData_LAeq1m_min[0].data
            })
            reparsedData_RT_array.push({
              "id": "LAeq,1 max",
              "color": "hsl(360, 100%, 50%)",
              "data": initData_LAeq1m_max[0].data
            })

            setDatosRT(reparsedData_RT_array);
            setDatos(reparsedData_LAeq1m_array)
            setFetchingData(false)
          } else {
            console.log("DATA", data)
            parsedData = new Array()
            parsedData_min = new Array()
            parsedData_max = new Array()
            parsedData_90 = new Array()
            parsedData_10 = new Array()
            parsedDataComplete = new Array()
            parsedDataComplete_min = new Array()
            parsedDataComplete_max = new Array()
            parsedDataComplete_90 = new Array()
            parsedDataComplete_10 = new Array()
            parsedData_LAeq1m = new Array()
            parsedData_LAmin1m = new Array()
            parsedData_LAmax1m = new Array()
            parsedData_LA901m = new Array()
            parsedData_LA101m = new Array()
            parsedDataCSV = new Array()
            let fecha
            let dia
            let mes
            let hora
            let horaMas
            let minuto
            let minutoMas
            let cadenaFecha
            let cadenaFechaCorta
            let num_ceros
            let missedHoles = new Array()

            let timestampNext
            let timestampNow

            //parsedDataCSV.push(["LAeq1m", "LAmin", "LAmax", "timestamp", "date"])
              
            dia = new Date(data[0].timestamp).getDate()
            mes = new Date(data[0].timestamp).getMonth() + 1
            csvDia = String(dia)
            csvMes = String(mes)
            csvYear = String(new Date(data[0].timestamp).getFullYear())
              
              
            for (var i = 0; i < Object.keys(data).length; i++) {
              //console.log(data[i].timestamp)
              fecha = new Date(data[i].timestamp)
              hora = fecha.getHours()
              minuto = fecha.getMinutes()
              
              cadenaFechaCorta = hora + ":" + minuto
              cadenaFecha = hora + ":" + minuto + " dia " + dia + " mes " + mes


              if ( (props.device.uuid == '20m0000') || (props.device.uuid == '20m0001') || (props.device.uuid == '20m0003') 
                    || (props.device.uuid == '20m0004') || (props.device.uuid == '20m0005') || (props.device.uuid == '20m0006')
                    || (props.device.uuid == '20m0007') || (props.device.uuid == '20m0008') || (props.device.uuid == '20m0009')
                    || (props.device.uuid == '20m0010') || (props.device.uuid == '20m0011') || (props.device.uuid == '20m0012')
                    || (props.device.uuid == '20m0013') ) 
              
                    {
                

                      parsedData.push({"x": '', "y": ''})
                      parsedData[i].x = data[i].timestamp     //cadenaFecha //new Date(data[i].timestamp).toTimeString()
                      parsedData[i].y = ( -0.0006987*(parseFloat(data[i].dBA)*parseFloat(data[i].dBA)) + 1.057*parseFloat(data[i].dBA) - 6.529 ).toFixed(1)

                      parsedData_min.push({"x": '', "y": ''})
                      parsedData_min[i].x = data[i].timestamp
                      parsedData_min[i].y = ( -0.0006987*(parseFloat(data[i].dBA_min)*parseFloat(data[i].dBA_min)) + 1.057*parseFloat(data[i].dBA_min) - 6.529 ).toFixed(1)

                      parsedData_max.push({"x": '', "y": ''})
                      parsedData_max[i].x = data[i].timestamp
                      parsedData_max[i].y = ( -0.0006987*(parseFloat(data[i].dBA_max)*parseFloat(data[i].dBA_max)) + 1.057*parseFloat(data[i].dBA_max) - 6.529 ).toFixed(1)

                      parsedDataCSV.push([parsedData[i].y, parsedData_min[i].y, parsedData_max[i].y, hora, minuto, mes, dia, fecha, data[i].timestamp])
                      csvHeaders = ["LAeq1m", "LAmin", "LAmax", "hour", "minute", "month", "day", "date", "timestamp"]
                              
              }
              else {

                parsedData.push({"x": '', "y": ''})
                parsedData[i].x = data[i].timestamp     //cadenaFecha //new Date(data[i].timestamp).toTimeString()
                parsedData[i].y = ( -0.0006987*(parseFloat(data[i].dBA)*parseFloat(data[i].dBA)) + 1.057*parseFloat(data[i].dBA) - 6.529 ).toFixed(1)

                parsedData_min.push({"x": '', "y": ''})
                parsedData_min[i].x = data[i].timestamp
                parsedData_min[i].y = ( -0.0006987*(parseFloat(data[i].min)*parseFloat(data[i].min)) + 1.057*parseFloat(data[i].min) - 6.529 ).toFixed(1)

                parsedData_max.push({"x": '', "y": ''})
                parsedData_max[i].x = data[i].timestamp
                parsedData_max[i].y = ( -0.0006987*(parseFloat(data[i].max)*parseFloat(data[i].max)) + 1.057*parseFloat(data[i].max) - 6.529 ).toFixed(1)

                parsedData_90.push({"x": '', "y": ''})
                parsedData_90[i].x = data[i].timestamp
                parsedData_90[i].y = ( -0.0006987*(parseFloat(data[i].L90)*parseFloat(data[i].L90)) + 1.057*parseFloat(data[i].L90) - 6.529 ).toFixed(1)

                parsedData_10.push({"x": '', "y": ''})
                parsedData_10[i].x = data[i].timestamp
                parsedData_10[i].y = ( -0.0006987*(parseFloat(data[i].L10)*parseFloat(data[i].L10)) + 1.057*parseFloat(data[i].L10) - 6.529 ).toFixed(1)

                parsedDataCSV.push([parsedData[i].y, parsedData_min[i].y, parsedData_max[i].y, parsedData_90[i].y, parsedData_10[i].y, hora, minuto, mes, dia, fecha, data[i].timestamp])
                csvHeaders = ["LAeq1m", "LAmin", "LAmax", "LA90", "LA10", "hour", "minute", "month", "day", "date", "timestamp"]        

              }
              
              
            }

            
            // Tenemos un array de datos, que deberían ser 1440
            // Vamos a ir completando el array final de datos

            // necesitamos conocer el día exacto en el que empieza nuestro array
            let myDate = new Date(parsedData[0].x)
            let myDate2 = myDate - (myDate.getHours()*3600000) - (myDate.getMinutes()*60000)
            //console.log("FECHA INICIAL", myDate.toString())

            // COMPROBACIÓN DE CONSISTENCIA DE LOS DATOS DE PARSED_DATA
            for (var i = 0; i < parsedData.length-1; i++) {
              // no deben existir dos datos en el mismo minuto, supone un duplicado
              if ( ((new Date(parsedData[i].x).getHours())*60 + new Date(parsedData[i].x).getMinutes()) == 
                      ((new Date(parsedData[i+1].x).getHours())*60 + new Date(parsedData[i+1].x).getMinutes()) ) {
                parsedData.splice(i,1) // eliminamos el dato "duplicado"
              }
            }
            

            let myMinute = 0
            for (var i = 0; i < 1440; i++) {

              // si quedan datos que procesar
              if (parsedData.length > 0) {
                parsedDataComplete.push({"x": '', "y": ''})
                parsedDataComplete_min.push({"x": '', "y": ''})
                parsedDataComplete_max.push({"x": '', "y": ''})
                parsedDataComplete_90.push({"x": '', "y": ''})
                parsedDataComplete_10.push({"x": '', "y": ''})

                // Vamos a crear un Array de 1440 datos

                //console.log("MINUTO DATO",( (new Date(parsedData[0].x).getHours())*60 + new Date(parsedData[0].x).getMinutes() ))
                //console.log("MINUTO LOOP", myMinute)
              
                
                // Si nuestro siguiente dato no se corresponde con el minuto en el que estamos, rellenaremos con cero
                if ( ( (new Date(parsedData[0].x).getHours())*60 + new Date(parsedData[0].x).getMinutes() ) != myMinute) {
                  parsedDataComplete[i].x = myDate2
                  parsedDataComplete[i].y = 0
                  parsedDataComplete_min[i].x = myDate2
                  parsedDataComplete_min[i].y = 0
                  parsedDataComplete_max[i].x = myDate2
                  parsedDataComplete_max[i].y = 0
                  parsedDataComplete_90[i].x = myDate2
                  parsedDataComplete_90[i].y = 0
                  parsedDataComplete_10[i].x = myDate2
                  parsedDataComplete_10[i].y = 0

                  
                } else {
                  // Si existe correspondencia entre el minuto en el que estamos y el del dato, lo introducimos
                  parsedDataComplete[i].x = myDate2
                  parsedDataComplete[i].y = parsedData[0].y
                  parsedDataComplete_min[i].x = myDate2
                  parsedDataComplete_min[i].y = parsedData_min[0].y
                  parsedDataComplete_max[i].x = myDate2
                  parsedDataComplete_max[i].y = parsedData_max[0].y

                  if ( !( (props.device.uuid == '20m0000') || (props.device.uuid == '20m0001') || (props.device.uuid == '20m0003') 
                    || (props.device.uuid == '20m0004') || (props.device.uuid == '20m0005') || (props.device.uuid == '20m0006')
                    || (props.device.uuid == '20m0007') || (props.device.uuid == '20m0008') || (props.device.uuid == '20m0009')
                    || (props.device.uuid == '20m0010') || (props.device.uuid == '20m0011') || (props.device.uuid == '20m0012')
                    || (props.device.uuid == '20m0013') ) ) 
              
                    {
                      parsedDataComplete_90[i].x = myDate2
                      parsedDataComplete_90[i].y = parsedData_90[0].y
                      parsedDataComplete_10[i].x = myDate2
                      parsedDataComplete_10[i].y = parsedData_10[0].y
                    }
                  
                  
                  // ahora desechamos el dato del Array original
                  parsedData.shift()
                  parsedData_min.shift()
                  parsedData_max.shift()
                  
                  
                  parsedData_90.shift()
                  parsedData_10.shift()



                }

                myDate2 = myDate2 + 60000 // pasamos la fecha al minuto siguiente
                myMinute += 1 // añadimos un minuto más

              } else {

                // si no quedan datos que procesar, debemos rellenar con ceros lo que falte

                let lastZeros = 1440 - parsedDataComplete.length
                console.log("LAST ZEROS", lastZeros)
                for (var j = 0; j < lastZeros; j++) {
                  parsedDataComplete.push({"x": myDate2, "y": 0})
                  parsedDataComplete_min.push({"x": myDate2, "y": 0})
                  parsedDataComplete_max.push({"x": myDate2, "y": 0})
                  parsedDataComplete_90.push({"x": myDate2, "y": 0})
                  parsedDataComplete_10.push({"x": myDate2, "y": 0})
                  myDate2 = myDate2 + 60000 // pasamos la fecha al minuto siguiente
                  myMinute += 1 // añadimos un minuto más
                }
                console.log("parsedDataComplete",parsedDataComplete)

              }
            }

            
            console.log("DATOS COMPLETOS", parsedDataComplete.length)
            console.log("MINUTOS COMPLETOS", myMinute)


            parsedData_LAeq1m.push({
              "id": "LAeq,1m",
              "color": "hsl(360, 100%, 0%)",
              "data": parsedDataComplete
            })
            
            
            parsedData_LAmin1m.push({
              "id": "LAmin,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_min
            })

            parsedData_LAmax1m.push({
              "id": "LAmax,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_max
            })

            parsedData_LA901m.push({
              "id": "LA90,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_90
            })

            parsedData_LA101m.push({
              "id": "LA10,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_10
            })
            
  
            
            setParsedData_LAeq1m_array(parsedData_LAeq1m)
            setParsedData_LAmin1m_array(parsedData_LAmin1m)
            setParsedData_LAmax1m_array(parsedData_LAmax1m)
            setParsedData_LA901m_array(parsedData_LA901m)
            setParsedData_LA101m_array(parsedData_LA101m)

            reparsedData_LAeq1m_array = new Array()
            reparsedData_LAmin1m_array = new Array()
            reparsedData_LAmax1m_array = new Array()
            reparsedData_LA901m_array = new Array()
            reparsedData_LA101m_array = new Array()

            reparsedData_LAeq1m_array.push({
              "id": "LAeq,1m",
              "color": "hsl(360, 100%, 0%)",
              "data": parsedDataComplete
            })
            
            
            reparsedData_LAmin1m_array.push({
              "id": "LAmin,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_min
            })

            reparsedData_LAmax1m_array.push({
              "id": "LAmax,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_max
            })

            reparsedData_LA901m_array.push({
              "id": "LA90,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_90
            })

            reparsedData_LA101m_array.push({
              "id": "LA10,1m",
              "color": "hsl(360, 100%, 50%)",
              "data": parsedDataComplete_10
            })


            console.log("LENGHT DATOS", parsedData_LAeq1m[0].data.length)
            

            let kl
            for (var i = 0; i < reparsedData_LAeq1m_array[0].data.length; i++) {
              kl = new Date(reparsedData_LAeq1m_array[0].data[i].x).getHours() + ":" + ("0" + new Date(reparsedData_LAeq1m_array[0].data[i].x).getMinutes()).substr(-2)
              reparsedData_LAeq1m_array[0].data[i].x = kl
              reparsedData_LAmin1m_array[0].data[i].x = kl
              reparsedData_LAmax1m_array[0].data[i].x = kl
              reparsedData_LA901m_array[0].data[i].x = kl
              reparsedData_LA101m_array[0].data[i].x = kl
            }

            
            // por defecto mostramos los datos de LAeq1m
            setDatos(reparsedData_LAeq1m_array)
            //setDatos(parsedDataComplete)

            setDatosCSV(parsedDataCSV)


            // COMPROBACIÓN DESFASE HORAS
            let loopCompruebaReparsed = 0
            let loopCompruebaParsed = 0
            let fechaDate = 0
            for (var i = 0; i < reparsedData_LAeq1m_array[0].data.length; i++) {
              if (parseFloat(reparsedData_LAeq1m_array[0].data[i].y) > 0) {  // si hay dato
                 fechaDate = new Date(data[loopCompruebaParsed].timestamp)
                 console.log("POS", loopCompruebaReparsed, loopCompruebaParsed, reparsedData_LAeq1m_array[0].data[i].x, fechaDate.getHours(), fechaDate.getMinutes(), 
                              reparsedData_LAeq1m_array[0].data[i].y, ( -0.0006987*(parseFloat(data[loopCompruebaParsed].dBA)*parseFloat(data[loopCompruebaParsed].dBA)) + 1.057*parseFloat(data[loopCompruebaParsed].dBA) - 6.529 ).toFixed(1))
                 loopCompruebaParsed ++
                 loopCompruebaReparsed ++
              } else {
                loopCompruebaReparsed ++
              }

            }


            setFetchingData(false)
          }
        })
        .catch(error => console.log('Error while fetching:', error))

  }


  


  const handleClick2 = () => {
    setFetchingData(true)
    let myID = props.device.chipID
    console.log(myID)
    console.log("VALUE")
    console.log(value)
    let data1hora = new Date()
    data1hora.setHours(data1hora.getHours()-1)
    var request = fetch('https://iubrir36s6.execute-api.us-west-2.amazonaws.com/default/fetchDynamoDBdata?ID='+myID+'&timestamp='+data1hora.getTime().toString(),{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',
                'x-api-key': 'zgIVUT2fX76zeEXbpzp4p3DTJZ59pEtK9wpTm2A8'
                //'Access-Control-Allow-Origin': '*', // Required for CORS support to work
                //'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
                //'Access-Control-Allow-Methods' : 'GET,HEAD,OPTIONS,POST,PUT'
            }
        })
        .then(response => response.json())
        .then((data) => { 

          console.log("DATOS RECIBIDOS")
          console.log(data)
          
          for (var i = 0; i < data.length; i++) {
            initData_LAeq1m[0].data[i].x = new Date(data[i].timestamp).getHours() + ":" + ("0" + new Date(data[i].timestamp).getMinutes()).substr(-2)
            initData_LAeq1m_min[0].data[i].x = new Date(data[i].timestamp).getHours() + ":" + ("0" + new Date(data[i].timestamp).getMinutes()).substr(-2)
            initData_LAeq1m_max[0].data[i].x = new Date(data[i].timestamp).getHours() + ":" + ("0" + new Date(data[i].timestamp).getMinutes()).substr(-2)

            //initData_LAeq1m[0].data[i].y = data[i].dBA
            //initData_LAeq1m_min[0].data[i].y = data[i].L90
            //initData_LAeq1m_max[0].data[i].y = data[i].L10
            initData_LAeq1m[0].data[i].y = ( -0.0006987*(parseFloat(data[i].dBA)*parseFloat(data[i].dBA)) + 1.057*parseFloat(data[i].dBA) - 6.529 ).toFixed(1)
            initData_LAeq1m_min[0].data[i].y = ( -0.0006987*(parseFloat(data[i].L90)*parseFloat(data[i].L90)) + 1.057*parseFloat(data[i].L90) - 6.529 ).toFixed(1)
            initData_LAeq1m_max[0].data[i].y = ( -0.0006987*(parseFloat(data[i].L10)*parseFloat(data[i].L10)) + 1.057*parseFloat(data[i].L10) - 6.529 ).toFixed(1)
          }
          
      
          reparsedData_RT_array = new Array()
          reparsedData_RT_array.push({
            "id": "LAeq,1m",
            "color": "hsl(360, 100%, 50%)",
            "data": initData_LAeq1m[0].data
          })
          reparsedData_RT_array.push({
            "id": "LA90 1m",
            "color": "hsl(360, 100%, 50%)",
            "data": initData_LAeq1m_min[0].data
          })
          reparsedData_RT_array.push({
            "id": "LA10 1m",
            "color": "hsl(360, 100%, 50%)",
            "data": initData_LAeq1m_max[0].data
          })
      
          setDatosRT(reparsedData_RT_array);
          setFetchingData(false)
    

        })
        .catch(error => console.log('Error while fetching:', error))
  }




  
  useEffect(() => {

    fetchDeviceById(deviceId)
    // inicialización de la gráfica en Tiempo en Real
    initParsedData_LAeq1m = new Array()
    initParsedData_LAeq1m_min = new Array()
    initParsedData_LAeq1m_max = new Array()
    initData_LAeq1m = new Array()
    initData_LAeq1m_min = new Array()
    initData_LAeq1m_max = new Array()

    if (justOnce) {
      for (var i = 0; i < 60; i++) {
          initParsedData_LAeq1m.push({"x": '', "y": ''})
          initParsedData_LAeq1m[i].x = i
          initParsedData_LAeq1m[i].y = 0
          initParsedData_LAeq1m_min.push({"x": '', "y": ''})
          initParsedData_LAeq1m_min[i].x = i
          initParsedData_LAeq1m_min[i].y = 0
          initParsedData_LAeq1m_max.push({"x": '', "y": ''})
          initParsedData_LAeq1m_max[i].x = i
          initParsedData_LAeq1m_max[i].y = 0
      }
      initData_LAeq1m.push({
          "id": "LAeq,1m",
          "color": "hsl(360, 100%, 0%)",
          "data": initParsedData_LAeq1m
      })
      initData_LAeq1m_min.push({
        "id": "LA90 1m",
        "color": "hsl(360, 100%, 0%)",
        "data": initParsedData_LAeq1m_min
    })
    initData_LAeq1m_max.push({
      "id": "LA10 1m",
      "color": "hsl(360, 100%, 0%)",
      "data": initParsedData_LAeq1m_max
    })
      setJustOnce(false)
      console.log("PASO POR AQUI SOLO UNA VEZZZZZZZZZ")
    }
    
    return () => {
      console.log("CLEANING UP!!!")
      window.location.reload()
    }
    
  }, [deviceId, fetchDeviceById])

  
  const { device } = props
  
  if (isFetching || deviceId !== device.id) { return <Spinner /> }
  if (fetchingData) { return <Spinner /> }

  else if (count == 1) { // Forzamos una única ejecución de esta parte del código (es una chapuza, pero funciona)
    console.log("PROPPPPPPS")
    console.log(props.device.uuid)

    var AWS = require('aws-sdk')
    var AWSIoTData = require('aws-iot-device-sdk')
    var AWSConfiguration = {
      poolId: process.env.REACT_APP_IDENTITY_POOL_ID, // 'YourCognitoIdentityPoolId'
      host: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
      region: process.env.REACT_APP_REGION
    }
    var currentlySubscribedTopic = 'mini/data/'
    var clientId = 'mqtt-explorer-' + (Math.floor((Math.random() * 100000) + 1))

    //
    // Initialize our configuration.
    //

    AWS.config.region = AWSConfiguration.region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWSConfiguration.poolId
    });


    //
    // Create the AWS IoT device object.  Note that the credentials must be 
    // initialized with empty strings; when we successfully authenticate to
    // the Cognito Identity Pool, the credentials will be dynamically updated.
    //
    const mqttClient = AWSIoTData.device({
      //
      // Set the AWS region we will operate in.
      //
      region: AWS.config.region,
      //
      ////Set the AWS IoT Host Endpoint
      host:AWSConfiguration.host,
      //
      // Use the clientId created earlier.
      //
      clientId: clientId,
      //
      // Connect via secure WebSocket
      //
      protocol: 'wss',
      //
      // Set the maximum reconnect time to 8 seconds; this is a browser application
      // so we don't want to leave the user waiting too long for reconnection after
      // re-connecting to the network/re-opening their laptop/etc...
      //
      maximumReconnectTimeMs: 8000,
      //
      // Enable console debugging information (optional)
      //
      debug: true,
      //
      // IMPORTANT: the AWS access key ID, secret key, and sesion token must be 
      // initialized with empty strings.
      //
      accessKeyId: '',
      secretKey: '',
      sessionToken: ''
    });

    //
    // Attempt to authenticate to the Cognito Identity Pool.  Note that this
    // example only supports use of a pool which allows unauthenticated 
    // identities.
    //

    var cognitoIdentity = new AWS.CognitoIdentity()
    AWS.config.credentials.get(function(err, data) {
      if (!err) {
          console.log('retrieved identity: ' + AWS.config.credentials.identityId);
          var params = {
            IdentityId: AWS.config.credentials.identityId
          };
          cognitoIdentity.getCredentialsForIdentity(params, function(err, data) {
            if (!err) {
                //
                // Update our latest AWS credentials; the MQTT client will use these
                // during its next reconnect attempt.
                //
                mqttClient.updateWebSocketCredentials(data.Credentials.AccessKeyId,
                  data.Credentials.SecretKey,
                  data.Credentials.SessionToken);
            } else {
                console.log('error retrieving credentials: ' + err);
                alert('error retrieving credentials: ' + err);
            }
          })
      } else {
          console.log('error retrieving identity:' + err);
          alert('error retrieving identity: ' + err);
      }
    })


    count += 1
    if ( (props.device.uuid == '20m0000') || (props.device.uuid == '20m0001') || (props.device.uuid == '20m0003') 
          || (props.device.uuid == '20m0004') || (props.device.uuid == '20m0005') || (props.device.uuid == '20m0006')
          || (props.device.uuid == '20m0007') || (props.device.uuid == '20m0008') || (props.device.uuid == '20m0009')
          || (props.device.uuid == '20m0010') || (props.device.uuid == '20m0011') || (props.device.uuid == '20m0012')
          || (props.device.uuid == '20m0013') ) {
      mqttClient.subscribe(currentlySubscribedTopic+props.device.uuid)
    }
    else {
      mqttClient.subscribe(currentlySubscribedTopic+props.device.chipID)
    }
    

    //
    // Install connect/reconnect event handlers.
    //
    mqttClient.on('connect', function(message) {
      console.log('CONECTADO A dBA!!')
      console.log(props.device.uuid)
    })

    mqttClient.on('reconnect', function(message) {
      console.log('RECONECTADO A dBA!!')
    })

    mqttClient.on('message', function(topic,payload) {

      console.log(topic)
      console.log(payload.toString())
      var recv = JSON.parse(payload)

      let dBA_1min = ( -0.0006987*(parseFloat(recv.dBA)*parseFloat(recv.dBA)) + 1.057*parseFloat(recv.dBA) - 6.529 ).toFixed(1)
      let dBA_1min_min = ( -0.0006987*(parseFloat(recv.L90)*parseFloat(recv.L90)) + 1.057*parseFloat(recv.L90) - 6.529 ).toFixed(1)
      let dBA_1min_max = ( -0.0006987*(parseFloat(recv.L10)*parseFloat(recv.L10)) + 1.057*parseFloat(recv.L10) - 6.529 ).toFixed(1)

      
      setDba_1min(dBA_1min)
      setDba_1min_min(dBA_1min_min)
      setDba_1min_max(dBA_1min_max)

      onData(dBA_1min, dBA_1min_min, dBA_1min_max)
    })
    
  }

  if (arrancamos) {

    value.setHours(0,0,0,0)  // last midnight, para la query general
    handleClick2()  // query última hora para el visor en tiempo real

    /*
    setTimeout(function(){
      console.log("SELECTED DEVICEEEEE")
      value.setHours(0,0,0,0);  // last midnight
      console.log(value)
      handleClick()
    },10000)
    */

    arrancamos = false
    
  
  } else {
  
    return (

      <section className="hero is-fullheight is-default is-bold">

        <div>
          <div className="hero-body">   

            
            <div className="container">
              
              <h1 className="title is-2">
                {device.name}
              </h1>
              <h2 className="title is-2">
                {device.description}
              </h2>
              
              <div className="columns is-vcentered">
                <div
                  style={{
                    width: '400px',
                    height: '300px',
                  }}
                >
                  <ReactSpeedometer 
                    fluidWidth={true}
                    minValue={20}
                    maxValue={120}
                    value={Number(dba_1min)}
                    needleColor="black"
                    needleHeightRatio={0.85}
                    labelFontSize={'14px'}
                    valueTextFontSize={'50px'}
                    paddingVertical={40}
                    startColor="green"
                    segments={10}
                    endColor="red"
                    textColor="black"
                  />
                </div>
              </div>
              
              <div className="hero-body"> 
                
                <div className="container">
                  <h1 className="subtitle">
                    LA90: {dba_1min_min}
                  </h1>
                </div>
                  <h1 className="subtitle">
                    LA10: {dba_1min_max}
                  </h1>
                
              </div>
              

            </div>

            
            <div className="container">
              <div className="hero-graph">
              <ResponsiveLine
                      data={datosRT}
                      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                      xScale={{ type: 'point' }}
                      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                      curve="step"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 90
                    }}
                      axisLeft={{
                          orient: 'right',
                          tickSize: 1,
                          tickPadding: 1,
                          tickRotation: 0,
                          legend: 'dBA',
                          legendOffset: -40,
                          legendPosition: 'middle'
                      }}
                      colors={{ scheme: 'category10' }}
                      enablePoints={false}
                      lineWidth={1}
                      pointSize={0}
                      animate={true}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabel="y"
                      pointLabelYOffset={-12}
                      enableArea={true}
                      enableSlices="x"
                      enableGridX={false}
                      enableGridY={false}
                      areaOpacity={0.4}
                      useMesh={false}
                      sliceTooltip={({ slice }) => {
                        return (
                          <>
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                {slice.points.map(point => (
                                    <div
                                        key={point.id}
                                        style={{
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{point.serieId}</strong> <strong>{point.data.yFormatted}</strong>
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                    <div
                                        style={{
                                            color: 'black',
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{props.t('DeviceDetail_5')}: {slice.points[0].data.xFormatted}</strong>
                                    </div>
                                
                            </div>
                          </>
                        )
                      }}
                      legends={[
                          {
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 100,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemDirection: 'left-to-right',
                              itemWidth: 80,
                              itemHeight: 20,
                              itemOpacity: 0.75,
                              symbolSize: 12,
                              symbolShape: 'circle',
                              symbolBorderColor: 'rgba(0, 0, 0, .5)',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemBackground: 'rgba(0, 0, 0, .03)',
                                          itemOpacity: 1
                                      }
                                  }
                              ]
                          }
                      ]}
                />
            </div>
            </div>

          </div>
        </div>




        <div className="hero-device">
          <div className="container has-text-centered">

          
            <div className="hero-body">
              <div className="container">
                <div className="columns is-vcentered">

                  <div className="column is-4">
                      <Calendar
                        onChange={onChange}
                        value={value}
                      />
                      
                      <div onClick={handleClick}>
                        <span className="button is-danger rounded2 raised">
                          {props.t('DeviceDetail_2')}
                        </span>
                      </div>
                      <div onClick={calculateLden}>
                        <span className="button is-danger rounded2 raised">
                          {props.t('DeviceDetail_2')}
                        </span>
                      </div>
                      <h3>
                        {value.toString()}
                      </h3>
                  </div>

                  
                </div>
                
                <div className="hero-body">
        
                    <div>
                      <h2>LAeq</h2>
                      <Checkbox
                        checked={checked_LAeq1m}
                        onChange={handleChangeCheckBox_LAeq1m}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    
                    <div>
                      <h2>LAmax</h2>
                      <Checkbox
                        checked={checked_LAmax1m}
                        onChange={handleChangeCheckBox_LAmax1m}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    
                    <div>
                      <h2>LAmin</h2>
                      <Checkbox
                        checked={checked_LAmin1m}
                        onChange={handleChangeCheckBox_LAmin1m}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    
                    <div>
                      <h2>LA90</h2>
                      <Checkbox
                        checked={checked_LA901m}
                        onChange={handleChangeCheckBox_LA901m}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                    
                    <div>
                    <h2>LA10</h2>
                      <Checkbox
                        checked={checked_LA101m}
                        onChange={handleChangeCheckBox_LA101m}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>

                    <Select className="hero-body-2"
                      onChange={handleChangeSelect2}
                      placeholder={props.t('DeviceDetail_1')}
                      options={options2}
                      defaultValue={options2[0]}
                    />

                    <Select className="hero-body-2"
                      onChange={handleChangeSelect}
                      placeholder={props.t('DeviceDetail_4')}
                      options={options}
                      defaultValue={options[0]}
                    />
                    
                </div>

                


              </div>
            </div>


            <div className="hero-graph">

              <ResponsiveLine
                      data={datos}
                      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                      xScale={{ type: 'point' }}
                      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                      curve="step"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 90
                      }}
                      axisLeft={{
                          orient: 'right',
                          tickSize: 1,
                          tickPadding: 1,
                          tickRotation: 0,
                          legend: 'dBA',
                          legendOffset: -40,
                          legendPosition: 'middle'
                      }}
                      colors={{ scheme: 'category10' }}
                      enablePoints={false}
                      lineWidth={1}
                      pointSize={0}
                      animate={false}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabel="y"
                      pointLabelYOffset={-12}
                      enableArea={true}
                      enableSlices="x"
                      enableGridX={false}
                      enableGridY={false}
                      areaOpacity={0.4}
                      useMesh={false}
                      sliceTooltip={({ slice }) => {
                        return (
                          <>
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                {slice.points.map(point => (
                                    <div
                                        key={point.id}
                                        style={{
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{point.serieId}</strong> <strong>{point.data.yFormatted}</strong>
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                    <div
                                        style={{
                                            color: 'black',
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{props.t('DeviceDetail_5')}: {slice.points[0].data.xFormatted}</strong>
                                    </div>
                                
                            </div>
                          </>
                        )
                      }}
                      legends={[
                          {
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 100,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemDirection: 'left-to-right',
                              itemWidth: 80,
                              itemHeight: 20,
                              itemOpacity: 0.75,
                              symbolSize: 12,
                              symbolShape: 'circle',
                              symbolBorderColor: 'rgba(0, 0, 0, .5)',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemBackground: 'rgba(0, 0, 0, .03)',
                                          itemOpacity: 1
                                      }
                                  }
                              ]
                          }
                      ]}
                />
            </div>
            
          </div>
        </div>

        <div className="hero-graph">
          <Timeline
            groups={groups}
            items={items}
            defaultTimeStart={moment().add(-12, 'hour')}
            defaultTimeEnd={moment().add(12, 'hour')}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return <div {...getRootProps()}>Left</div>;
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
        </div>
      
        <span className="button is-warning rounded2 raised">

          <CSVLink 
            filename={device.name+"_"+csvYear+"_"+csvMes+"_"+csvDia+".csv"}
            data={datosCSV}
            headers={ csvHeaders }>
              {props.t('DeviceDetail_3')}
          </CSVLink> 
        </span>
        <br />
        <br />
      </section>
      
    )

  }
}


const mapStateToProps = ({selectedDevice}) => (
  {
    device: selectedDevice.item,
    isFetching: selectedDevice.isFetching
  }
)


export default withNamespaces()(withAuthorizationOrAdmin("admin")(connect(mapStateToProps, {fetchDeviceById})(DeviceDetail)))