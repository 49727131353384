

import React from 'react'
import withAuthorizationOrAdmin from 'components/hoc/withAuthorizationOrAdmin'
import { withNamespaces } from 'react-i18next'

import { fetchUserDevices } from 'actions'
import { connect } from 'react-redux'
import DeviceItem from 'components/device/DeviceItem'


class UserDevices extends React.Component {

    componentDidMount() {
        const { auth: { user }, dispatch } = this.props
        dispatch(fetchUserDevices(user.uid))
    }


    render() {
        const { t, devices } = this.props
        return (
            <div className="container">
                <div className="content-wrapper">
                    <h1 className="title">{t('UserDevices_1')}</h1>
                    <div className="columns is-multiline">
                        {
                            devices.map(s => (
                                    <div key={s.id} className="column">
                                        <DeviceItem device={s} />
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({user}) => ({devices: user.devices})

export default withNamespaces()(withAuthorizationOrAdmin()(connect(mapStateToProps)(UserDevices)))