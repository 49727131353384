/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';

import Flag from 'react-world-flags';


const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
}


const Navbar = (props) => {

  const { user, isAuth } = props.auth
  const { logout, loadFresh } = props

  useEffect(() => {
    if (!loadFresh) { return }

    const script = document.createElement('script')
    script.src = `${process.env.PUBLIC_URL}/js/fresh.js`
    script.async = true
    document.body.appendChild(script)
  }, [loadFresh])

  return (
    <nav 
      id={props.id || ''}
      className="navbar is-fresh is-transparent no-shadow" 
      role="navigation" 
      aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
             <figure className="avatar">
                        <img src="../logo_audiospook_2.svg" alt="audiospook_logo"/>
             </figure>
          </Link>

          <a className="navbar-item is-hidden-desktop is-hidden-tablet">
            <div id="menu-icon-wrapper" className="menu-icon-wrapper" style={{visibility: 'visible'}}>
              <svg width="1000px" height="1000px">
                  <path className="path1" d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"></path>
                  <path className="path2" d="M 300 500 L 700 500"></path>
                  <path className="path3" d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"></path>
              </svg>
              <button id="menu-icon-trigger" className="menu-icon-trigger"></button>
            </div>
          </a>

          <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbar-menu" className="navbar-menu is-static">
          <div className="navbar-start">
            <a className="navbar-item is-hidden-mobile">
              <div id="menu-icon-wrapper" className="menu-icon-wrapper" style={{visibility: 'visible'}}>
                <svg width="1000px" height="1000px">
                    <path className="path1" d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"></path>
                    <path className="path2" d="M 300 500 L 700 500"></path>
                    <path className="path3" d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"></path>
                </svg>
                <button id="menu-icon-trigger" className="menu-icon-trigger"></button>
              </div>
            </a>
          </div>

          <div className="navbar-end">

            { user &&
              <div className="navbar-item is-secondary user-welcome">
                {`${props.t('Navbar_Hello')} ${user.fullName}`}
              </div>
            }

            <Link to="/" className="navbar-item is-secondary">
              {props.t('Navbar_Finder')}
            </Link>

            <Link to="/services" className="navbar-item is-secondary">
              {props.t('Navbar_Services')}
            </Link>

            <Link to="/faq" className="navbar-item is-secondary">
              {props.t('Navbar_FAQ')}
            </Link>

            {
              isAuth && (user.role === 'admin' || user.role === 'user') &&
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link">
                  {props.t('Navbar_Manage')}
                  </a>

                  <div className="navbar-dropdown">
                    <Link to="/devices/me" className="navbar-item is-secondary">
                      {props.t('Navbar_Manage_1')}
                    </Link>
                  </div>

                  
              </div>
            }
            
            
            { !isAuth &&
              <React.Fragment>
                <Link to="/login" className="navbar-item is-secondary modal-trigger" data-modal="auth-modal">
                  {props.t('Navbar_Login')}
                </Link>
                <Link to="/register" className="navbar-item">
                  <span className="button signup-button rounded secondary-btn raised">
                    {props.t('Navbar_SignUp')}
                  </span>
                </Link>
              </React.Fragment>
            }
            {
              isAuth &&
              <div onClick={logout} className="navbar-item">
                <span className="button signup-button is-danger rounded raised">
                  {props.t('Navbar_Logout')}
                </span>
              </div>
            }


            {
              isAuth && user.role === 'admin' &&
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link">
                  {props.t('Navbar_Admin')}
                  </a>

                  <div className="navbar-dropdown">
                    <Link to="/devices/new" className="navbar-item is-secondary">
                      {props.t('Navbar_Admin_DeviceNew')}
                    </Link>
                    <Link to="/controlpanel" className="navbar-item is-secondary">
                      {props.t('Navbar_Admin_Users')}
                    </Link>
                  </div>

                  
              </div>
            }


            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                {props.t('Language')}
              </a>

              <div className="navbar-dropdown"> 
                <a className="navbar-item" onClick={() => changeLanguage('en')}>
                  <Flag code={ "gb" } />
                </a>
                <a className="navbar-item" onClick={() => changeLanguage('th')}>
                  <Flag code={ "th" } />
                </a>
                <a className="navbar-item" onClick={() => changeLanguage('es')}>
                  <Flag code={ "es" } />
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </nav>
  )
}

export default withNamespaces()(Navbar)