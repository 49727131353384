import React, {useState} from 'react'
import { Redirect } from 'react-router-dom'
import withAuthorizationOrAdmin from 'components/hoc/withAuthorizationOrAdmin'
import { withNamespaces } from 'react-i18next'
import axios from 'axios'

const Services = (props) => {

    const { t } = props

    const [ redirect, setRedirect ] = useState(false)
    const [inputs, setInputs] = useState({email: '', name: '', subject: '', description: ''})

    const handleChange = e => {
        const {name, value} = e.target
        setInputs(prev => ({...prev, [name]: value }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        //destructure from inputs 
        const {email,name,subject,description} = inputs
        
        /*
        axios.post('/sendtome', {
          //make an object to be handled from req.body on the backend. 
          email,
          name,
          subject,
          //change the name to represent text on the backend.
          text: description
        }) 
        */
        axios.request({
            method: 'POST',
            url: '/sendtome',
            data: {
                email,
                name,
                subject,
                text: description
            }
        })
        .then(() => setRedirect(true))
        .catch(() => alert('SOME ERROR!'))
    }


    if (redirect) { return <Redirect to="/" />}

    return (

        <div className="auth-page">
            <div className="container has-text-centered">
                <div className="column is-4 is-offset-4"></div>
                <h3 className="title has-text-grey">{t('Services_1')}</h3>
                <div className="hero-body">
                <div className="container">
                <div className="columns is-vcentered">
                    
                    <div className="column is-5 is-offset-1 landing-caption">
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <div className="control">
                                    <input type="text" className="input is-medium" placeholder={t('Services_2')} name="email" value={inputs.email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="text" className="input is-medium" placeholder={t('Services_3')} name="name" value={inputs.name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="text" className="input is-medium" placeholder={t('Services_4')} name="subject" value={inputs.subject} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <textarea name="description" className="input is-medium" placeholder={t('Services_5')} value={inputs.description} onChange={handleChange} cols="30" rows="10"></textarea>
                                </div>
                            </div>
                            <button className="button cta rounded primary-btn raised">{t('Services_6')}</button>
                        </form>
                    </div>

                    <div className="column is-5 is-offset-1">
                    <figure className="image is-4by3">
                        <img src={process.env.PUBLIC_URL + '/logo_audiospook_1.svg'} alt="Description" />
                    </figure>
                    </div>

                </div>
                </div>
            </div>
        </div>
      </div>

    )

}


export default withNamespaces()(withAuthorizationOrAdmin("admin")(Services))