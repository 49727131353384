
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import withAuthorizationAdmin from 'components/hoc/withAuthorizationAdmin'
import { fetchUsers } from 'api'
import UserItem from 'components/user/UserItem'


const ControlPanel = (props) => {

    const { t, auth } = props

    const [ users, setUsers ] = useState([])


    const handleSubmit = () => {
        fetchUsers()
            .then(result => {
                console.log(result)
                setUsers(result)
            })
            .catch(() => alert('SOME ERROR!'))
    }

    


    return (

        <div className="create-page">
            <div className="container">
                <div className="form-container">
                    <h1 className="title">{t('ControlPanel_1')}</h1>                     
                        <div className="field is-grouped">
                            <div className="control">
                                <button
                                    onClick={handleSubmit}
                                    type="button" 
                                    className="button is-link">{t('ControlPanel_2')}</button>
                            </div>
                            
                        </div>
                </div>
                <div className="container">
                    {users.length != 0 ? 
                        <div className="content-wrapper">
                            <h1 className="title">{t('ControlPanel_2')}</h1>
                            <div className="columns is-multiline">
                                {
                                    users.map(s => (
                                            <div key={s.id} className="column">
                                                <UserItem user={s} />
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                        : <div></div>
                    }
                </div>
            </div>
        </div>


    )
}

export default withNamespaces()(withAuthorizationAdmin("admin")(ControlPanel))