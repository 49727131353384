
import { FETCH_USER_DEVICES_SUCCESS } from 'types'


const INITIAL_STATE = {
  devices: []
}

const user = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case FETCH_USER_DEVICES_SUCCESS:
      return { devices: action.devices }
    
    default:
      return state
  }
}

export default user