
import firebase from 'firebase/app'
import 'firebase/auth'

import db from 'db'

// ------------ SERVICES ------------------------

export const fetchServiceById = serviceId => 
    db
        .collection('spot_services')
        .doc(serviceId)
        .get()
        .then(snapshot => ({id: snapshot.id, ...snapshot.data()}))


export const fetchServices = () => 
    db
        .collection('spot_services')
        .get()
        .then(snapshot => {
        const services = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))

        return services
        })



// ------------ SERVICES END ------------------------



// ------------ USERS ----------------------------

export const fetchUsers = () => 
    db
        .collection('spot_profiles')
        .get()
        .then(snapshot => {
        const profiles = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
        //console.log("PASO POR EL ACTIONS")
        return profiles
        })


// ------------ USERS END ------------------------




// ------------ DEVICES ------------------------

export const fetchUserDevices = userId => 
    db
        .collection('spot_devices')
        .where("user", "==", userId)
        .get()
        .then(snapshot => {
        const devices = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))

        return devices
        })


export const createDevice = newDevice => {
    return db
        .collection('spot_devices')
        .add(newDevice)
        .then(docRef => docRef.id)
    }


export const fetchDeviceById = deviceId => 
    db
        .collection('spot_devices')
        .doc(deviceId)
        .get()
        .then(snapshot => ({id: snapshot.id, ...snapshot.data()}))



export const setLAeq1mDeviceTrigger = (deviceId, value, LAeq1m_trigger_activated, LAmax1m_trigger_activated, LAmin1m_trigger_activated) =>
    db
        .collection('spot_devices')
        .doc(deviceId)
        .set({LAeq1m_trigger: value, LAeq1m_trigger_activated: LAeq1m_trigger_activated, LAmax1m_trigger_activated: LAmax1m_trigger_activated, LAmin1m_trigger_activated: LAmin1m_trigger_activated}, {merge: true})

export const setLAmax1mDeviceTrigger = (deviceId, value, LAeq1m_trigger_activated, LAmax1m_trigger_activated, LAmin1m_trigger_activated) =>
    db
        .collection('spot_devices')
        .doc(deviceId)
        .set({LAmax1m_trigger: value, LAeq1m_trigger_activated: LAeq1m_trigger_activated, LAmax1m_trigger_activated: LAmax1m_trigger_activated, LAmin1m_trigger_activated: LAmin1m_trigger_activated}, {merge: true})
        

export const setLAmin1mDeviceTrigger = (deviceId, value, LAeq1m_trigger_activated, LAmax1m_trigger_activated, LAmin1m_trigger_activated) =>
    db
        .collection('spot_devices')
        .doc(deviceId)
        .set({LAmin1m_trigger: value, LAeq1m_trigger_activated: LAeq1m_trigger_activated, LAmax1m_trigger_activated: LAmax1m_trigger_activated, LAmin1m_trigger_activated: LAmin1m_trigger_activated}, {merge: true})
  


// ------------ DEVICES END ------------------------



// ------------ AUTH -------------------------------

const createUserProfile = (userProfile) =>
        db.collection('spot_profiles')
            .doc(userProfile.uid)
            .set(userProfile)

const createDeviceProfile = (deviceProfile) =>
        db.collection('spot_device_profiles')
            .doc(deviceProfile.uid)
            .set(deviceProfile)

export const register = async ({email, password, fullName, avatar}) => {
    try {
        const res = await firebase.auth().createUserWithEmailAndPassword(email,password)
        const { user } = res
        const userProfile = { uid: user.uid, fullName, email, avatar, services: [], description: '', role: 'user' }
        await createUserProfile(userProfile)
        return userProfile
    } catch (error) {
        return Promise.reject(error.message)
    }
}

export const registerDevice = async ({name, password, avatar}) => {
    try {
        const res = await firebase.auth().createUserWithEmailAndPassword(name+'@audiospook.com',password)
        const { user } = res
        const deviceProfile = { uid: user.uid, name, avatar, services: [], description: '', role: 'device' }
        await createDeviceProfile(deviceProfile)
        return deviceProfile
    } catch (error) {
        return Promise.reject(error.message)
    }
}


export const login = ({email, password}) =>
    firebase.auth().signInWithEmailAndPassword(email, password)
        .catch(error => Promise.reject(error.message))


        
export const logout = () => firebase.auth().signOut()



export const onAuthStateChanged = onAuthCallback => 
    firebase.auth().onAuthStateChanged(onAuthCallback)



export const getUserProfile = uid =>
    db.collection('spot_profiles')
        .doc(uid)
        .get()
        .then(snapshot => ({uid, ...snapshot.data()}))