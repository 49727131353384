
import React, { useState } from 'react'
import withAuthorizationAdmin from 'components/hoc/withAuthorizationAdmin'
import { withNamespaces } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { createDevice } from 'actions'

const DeviceNew = (props) => {

    const { t, auth } = props

    const [ redirect, setRedirect ] = useState(false)
    const [ deviceForm, setDeviceForm ] = useState({
        model: 'mini_0',
        uuid: '',
        chipID: '',
        name: '',
        key: '',
        description: '',
        image: ''
    })

    const handleChange = e => {
        const { name, value } = e.target
        setDeviceForm({...deviceForm, [name]: value})
    }

    const handleSubmit = () => {
        const { user } = auth
        createDevice(deviceForm, user.uid)
            .then(() => setRedirect(true))
            .catch(() => alert('SOME ERROR!'))
    }

    if (redirect) { return <Redirect to="/" />}

    return (

        <div className="create-page">
            <div className="container">
                <div className="form-container">
                    <h1 className="title">{t('DeviceCreate_1')}</h1>
                    <form>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_1')}</label>
                            <div className="control">
                                <div className="select">
                                <select name="model" onChange={handleChange}>
                                    <option value="mini_0">mini_0</option>
                                    <option value="S1">S1</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_10')}</label>
                            <div className="control">
                                <input
                                    onChange={handleChange}
                                    name="uuid"
                                    className="input"
                                    type="text"
                                    placeholder={t('DeviceCreate_4')} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_11')}</label>
                            <div className="control">
                                <input
                                    onChange={handleChange}
                                    name="chipID"
                                    className="input"
                                    type="text"
                                    placeholder={t('DeviceCreate_4')} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_3')}</label>
                            <div className="control">
                                <input
                                    onChange={handleChange}
                                    name="name"
                                    className="input"
                                    type="text"
                                    placeholder={t('DeviceCreate_4')} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_9')}</label>
                            <div className="control">
                                <input
                                    onChange={handleChange}
                                    name="key"
                                    className="input"
                                    type="text"
                                    placeholder={t('DeviceCreate_4')} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_5')}</label>
                            <div className="control">
                                <textarea
                                    onChange={handleChange}
                                    name="description"
                                    className="textarea"
                                    placeholder={t('DeviceCreate_4')}></textarea>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">{t('DeviceCreate_6')}</label>
                            <div className="control">
                                <input
                                    onChange={handleChange}
                                    name="image"
                                    className="input"
                                    type="text"
                                    placeholder={t('DeviceCreate_4')} />
                            </div>
                        </div>
                        <div className="field is-grouped">
                            <div className="control">
                                <button
                                    onClick={handleSubmit}
                                    type="button" 
                                    className="button is-link">{t('DeviceCreate_7')}</button>
                            </div>
                            <div className="control">
                                <button 
                                    className="button is-text">{t('DeviceCreate_8')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    )
}

export default withNamespaces()(withAuthorizationAdmin("admin")(DeviceNew))