

import withAuthorizationAdmin from 'components/hoc/withAuthorizationAdmin'
import { withNamespaces } from 'react-i18next';
import React from 'react'




class Admin extends React.Component {


  constructor() {
    super()
    this.state = {
      
    }
  }


  componentDidMount() {
    //
    // Initialize our configuration.
    //
    
  }


  render() {

     
    return (
      <div className="auth-page">
        <div >
              <div className="container has-text-centered">
                  <div className="column is-4 is-offset-4">
                    <h1> admin panel</h1>
                  </div>
              </div>
        </div>

      </div>
      
    )
  }
}


export default withNamespaces()(withAuthorizationAdmin("admin")(Admin))