


import withAuthorizationOrAdmin from 'components/hoc/withAuthorizationOrAdmin'
import { withNamespaces } from 'react-i18next';

import React from 'react'
import { withRouter } from "react-router"
import { connect } from 'react-redux'
import Plot from 'react-plotly.js';

import { fetchDeviceById } from 'actions'
import selectedDevice from 'reducers/selectedDevice';


//import Amplify from 'aws-amplify'
//import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'

/*
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
})

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: process.env.REACT_APP_REGION,
  aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
}))

Amplify.PubSub.subscribe('dBA').subscribe({
  next: data => console.log('Message received', data),
  error: error => console.error(error),
  close: () => console.log('Done'),
});
*/


var AWS = require('aws-sdk')
var AWSIoTData = require('aws-iot-device-sdk')
var AWSConfiguration = {
  poolId: process.env.REACT_APP_IDENTITY_POOL_ID, // 'YourCognitoIdentityPoolId'
  host: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
  region: process.env.REACT_APP_REGION
};
var currentlySubscribedTopic = 'mini/data/20m0004';
var clientId = 'mqtt-explorer-' + (Math.floor((Math.random() * 100000) + 1))
var mqttClient



class Secret extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      dBA: '',
      dBA_min: '',
      dBA_max: '',
      time: '',
      data_dBA: [],
      layout: {},
      frames: [],
      config: {}
    }

    const { fetchDeviceById, isFetching } = props
    fetchDeviceById(this.props.match.params.deviceId)
    

  }


  componentDidMount() {

    //
    // Initialize our configuration.
    //
    AWS.config.region = AWSConfiguration.region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWSConfiguration.poolId
    });


    //
    // Create the AWS IoT device object.  Note that the credentials must be 
    // initialized with empty strings; when we successfully authenticate to
    // the Cognito Identity Pool, the credentials will be dynamically updated.
    //
    mqttClient = AWSIoTData.device({
      //
      // Set the AWS region we will operate in.
      //
      region: AWS.config.region,
      //
      ////Set the AWS IoT Host Endpoint
      host:AWSConfiguration.host,
      //
      // Use the clientId created earlier.
      //
      clientId: clientId,
      //
      // Connect via secure WebSocket
      //
      protocol: 'wss',
      //
      // Set the maximum reconnect time to 8 seconds; this is a browser application
      // so we don't want to leave the user waiting too long for reconnection after
      // re-connecting to the network/re-opening their laptop/etc...
      //
      maximumReconnectTimeMs: 8000,
      //
      // Enable console debugging information (optional)
      //
      debug: true,
      //
      // IMPORTANT: the AWS access key ID, secret key, and sesion token must be 
      // initialized with empty strings.
      //
      accessKeyId: '',
      secretKey: '',
      sessionToken: ''
    });

    //
    // Attempt to authenticate to the Cognito Identity Pool.  Note that this
    // example only supports use of a pool which allows unauthenticated 
    // identities.
    //
    var cognitoIdentity = new AWS.CognitoIdentity();
    AWS.config.credentials.get(function(err, data) {
      if (!err) {
          console.log('retrieved identity: ' + AWS.config.credentials.identityId);
          var params = {
            IdentityId: AWS.config.credentials.identityId
          };
          cognitoIdentity.getCredentialsForIdentity(params, function(err, data) {
            if (!err) {
                //
                // Update our latest AWS credentials; the MQTT client will use these
                // during its next reconnect attempt.
                //
                mqttClient.updateWebSocketCredentials(data.Credentials.AccessKeyId,
                  data.Credentials.SecretKey,
                  data.Credentials.SessionToken);
            } else {
                console.log('error retrieving credentials: ' + err);
                alert('error retrieving credentials: ' + err);
            }
          });
      } else {
          console.log('error retrieving identity:' + err);
          alert('error retrieving identity: ' + err);
      }
    })



    console.log("MQTT STATE **********************")
    console.log(this.state)
    mqttClient.subscribe(currentlySubscribedTopic)


    
    //
    // Install connect/reconnect event handlers.
    //
    mqttClient.on('connect', function(message) {
      console.log('CONECTADO A dBA!!')
    })

    mqttClient.on('reconnect', function(message) {
      console.log('RECONECTADO A dBA!!')
    })

    mqttClient.on('message', function(topic,payload) {
      console.log(topic)
      console.log(payload.toString())
      this.setState({
        dBA: parseFloat(parseFloat(JSON.parse(payload.toString()).dBA).toFixed(1)),
        dBA_min: parseFloat(parseFloat(JSON.parse(payload.toString()).dBA_min).toFixed(1)),
        dBA_max: parseFloat(parseFloat(JSON.parse(payload.toString()).dBA_max).toFixed(1)),
        time: Date(parseInt(JSON.parse(payload.toString()).time))
      })
    }.bind(this))


  }



  

  render() {

    console.log(process.env)
      
    return (
      <div className="auth-page">
        <div >
              <div className="container has-text-centered">
                  <div className="column is-4 is-offset-4">
                    <h1> dBA 1m: {this.state.dBA}</h1>
                    <h1> dBA 1m min: {this.state.dBA_min}</h1>
                    <h1> dBA 1m max: {this.state.dBA_max}</h1>
                    <h1> time: {this.state.time}</h1>
                  </div>
              </div>
        </div>

        <Plot
          
        />
      </div>
      
    )
  }
}


const mapStateToProps = ({selectedDevice}) => (
  {
    device: selectedDevice.item,
    isFetching: selectedDevice.isFetching
  }
)

//export default withRouter(withNamespaces()(withAuthorizationAdmin("admin")(Secret)))
export default withRouter(withNamespaces()(withAuthorizationOrAdmin("admin")(connect(mapStateToProps, {fetchDeviceById})(Secret))))