import { 
  FETCH_SERVICES_SUCCESS, 
  FETCH_SERVICE_SUCCESS,
  FETCH_DEVICE_SUCCESS,
  FETCH_USERS_SUCCESS,
  REQUEST_SERVICE,
  REQUEST_DEVICE,
  SET_AUTH_USER, 
  RESET_AUTH_STATE,
  FETCH_USER_DEVICES_SUCCESS } from 'types'

import * as api from 'api'

export const fetchServices = () => dispatch =>
   api
    .fetchServices()
    .then(services => dispatch(
      {
        type: FETCH_SERVICES_SUCCESS,
        services
      }
    )
  )


export const fetchUserDevices = userId => dispatch =>
  api.fetchUserDevices(userId).then(devices => 
    dispatch({type: FETCH_USER_DEVICES_SUCCESS, devices}))


export const fetchServiceById = serviceId => (dispatch, getState) => {
  const lastService = getState().selectedService.item
  if (lastService.id && lastService.id === serviceId) { return Promise.resolve() }

  dispatch({type: REQUEST_SERVICE})
  return api
    .fetchServiceById(serviceId)
    .then(service => dispatch(
      {
        type: FETCH_SERVICE_SUCCESS,
        service
      }
    )
  )
}


// USERS START ---------------

export const fetchUsers = () => dispatch =>
   api
    .fetchUsers()
    .then(profiles => dispatch(
      {
        type: FETCH_USERS_SUCCESS,
        profiles
      }
    )
  )



// USERS END -----------------


// DEVICES START -------------

export const createDevice = (newDevice, userId) => {
  newDevice.user = userId

  return api.createDevice(newDevice)
}


export const fetchDeviceById = deviceId => (dispatch, getState) => {
  const lastDevice = getState().selectedDevice.item
  if (lastDevice.id && lastDevice.id === deviceId) { return Promise.resolve() }

  dispatch({type: REQUEST_DEVICE})

  return api
    .fetchDeviceById(deviceId)
    .then(device => dispatch(
      {
        type: FETCH_DEVICE_SUCCESS,
        device
      }
    )
  )
}



// DEVICES END -------------



// AUTH STARTS -------------

export const register = registerFormData => api.register({...registerFormData})
export const registerDevice = registerDeviceData => api.registerDevice({...registerDeviceData})
export const login = loginData => api.login({...loginData})
export const onAuthStateChanged = onAuthCallback => api.onAuthStateChanged(onAuthCallback)

export const logout = () => dispatch => 
  api.logout().then(_ => dispatch({user: null, type: SET_AUTH_USER})) 

export const storeAuthUser = authUser => dispatch => {
  dispatch({type: RESET_AUTH_STATE})
  if (authUser) {
    return api
      .getUserProfile(authUser.uid)
      .then(userWithProfile => dispatch({user: userWithProfile, type: SET_AUTH_USER}))
  } else {
    return dispatch({user: null, type: SET_AUTH_USER})
  }
}

// AUTH END -------------